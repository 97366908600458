import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import { useModalContext } from "../../../context/modalContext";
import { TextBox, SelectBox } from "devextreme-react";
import Loading from "../../loading/Loading";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import ModalControls from "../parts/modalControls/ModalControls";
import toast from "react-hot-toast";
import UserLeave from "../../notifications/userLeaveNotifications/UserLeave";
import IconSelect from "./iconsSelect/IconsSelect";
import validatedIcons from "./iconsSelect/validatedIcons";
import "./editmenuconfig.scss";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"

const EDITMENUCONFIG = () => {
    const msal = useMsal();
    const {
        setIsEditing,
        modalContent,
        isEditing,
        setFormInValidNotification,
        setNotificationData,
    } = useModalContext();

    const { isCreate, menuId } = modalContent;
    const [loading, setLoading] = useState(true);
    const [formValue, setFormValue] = useState();
    const [throwNotification, setThrowNotification] = useState(false);

    const [selectOverzicht, setSelectOverzicht] = useState();
    const [changeInForm, setChangeInForm] = useState(false);
    const [menuData, setMenuData] = useState();
    const [overviewLoading, setOverviewLoading] = useState(false);
    const [selectSearchFocus, setSelectSearchFocus] = useState(false);
    const [mandatory, setMandatory] = useState(false);
    const [defaultValue, setDefaultValue] = useState({});
    const [selectedIcon, setSelectedIcon] = useState();
    const [schedulers, setSchedulers] = useState();
    const [schedulerLoading, setSchedulerLoading] = useState(false);
    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const getItemData = async () => {
        setLoading(true);
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/menuItem/menuItems/${modalContent.parentData.data.id}`
            );
            const data = await response.json();
            setFormValue(data);

            if (data.overviewId) {
                setMandatory(false);
            } else {
                setMandatory(true);
            }
            if (data.icon) {
                setSelectedIcon(
                    validatedIcons.find((t) => t.value === data.icon)
                        ? data.icon
                        : "fa-dot-circle"
                );
            }

            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const getMenu = async () => {
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/menu`
            );
            const data = await response.json();
            setMenuData(data);
            return data;
        } catch (error) {
            throw error;
        }
    };

    const getCombinedMenuItems = useCallback(async () => {
        try {
            const overviewResponse = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/menuItem/menuItems/overviews`
            );
            const overviewData = await overviewResponse.json();
            const schedulerResponse = await msalFetch(null,
                `${appSettings.api.endpoint}/api/scheduler/list`
            );
            const schedulerData = await schedulerResponse.json();

            const powerReportResponse = await msalFetch(null,
                `${appSettings.api.endpoint}/api/power-report/index`
            );
            const powerReportData = await powerReportResponse.json();

            const overviewItems = overviewData.map(item => { return { label: `${item.name} (Lijst)`, id: `ov_${item.id}`, type: 'OVERVIEW', tableName: `${item.table.tableName}` } });
            const schedulerItems = schedulerData.map(item => { return { label: `${item.title} (Agenda)`, id: `sc_${item.id}`, type: 'SCHEDULER', tableName: item.tableName } });
            const powerReportItems = powerReportData.map(item => { return { label: `${item.title}`, id: `qv_${item.id}`, type: 'POWERREPORT', tableName: 'Power reports' } });

            const combinedItems = [...overviewItems, ...schedulerItems, ...powerReportItems];
            // const tableNames = new Set(combinedItems.map(item => item.tableName));
            // const sortedTableNames = [...tableNames].sort();
            // const items = sortedTableNames.map(tn => { return { key: tn, items: combinedItems.filter(ci => ci.tableName === tn) } });
            debugger;
            setSelectOverzicht(combinedItems);
        } catch (error) {

        }
    }, []);

    useEffect(() => {
        getCombinedMenuItems();
    }, [getCombinedMenuItems]);

    useEffect(() => {
        getMenu();

        setThrowNotification(false);
        setChangeInForm(false);
        if (!isCreate) {
            getItemData();
        } else {
            setFormValue({ icon: "", menuId: menuId, name: "", overviewId: null });
            setLoading(false);
        }
        //getOverviews();
    }, [modalContent, isEditing]);

    useEffect(() => {
    }, [formValue, setFormValue]);

    const dataSource = new DataSource({
        store: {
            type: 'array',
            data: selectOverzicht,
            key: 'id',
        },
        group: 'tableName',
    })


    const handleSaveClick = async () => {
        const url = `api/configurator/menuItem/menuItems?id=${formValue.id}`;

        let allDocument = document.getElementsByClassName("bl-mandatory-field");

        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }

        try {
            const res = await msalFetch(msal, `${appSettings.api.endpoint}/${url}`, {
                method: "PUT",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(formValue),
            });
            if (res.ok) {
                setChangeInForm(false);
                setIsEditing(false);
                setThrowNotification(false);
                toast.success("De gegevens zijn successvol opgeslagen...");
                const evt = new Event('menu-changed');
                document.dispatchEvent(evt);
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }

        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            throw error;
        }
    };

    const handleAddNew = async () => {
        let allDocument = document.getElementsByClassName("bl-mandatory-field");

        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }
        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/menuItem/menuItems/`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(formValue),
                }
            );
            if (res.ok) {
                setChangeInForm(false);
                setIsEditing(false);
                toast.success("De gegevens zijn successvol opgeslagen...");
                const evt = new Event('menu-changed');
                document.dispatchEvent(evt);
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }

        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            throw error;
        }
    };

    const handleOverviewChange = (e) => {
        setMandatory(false);
        let tempFormValue = formValue;
        if (formValue.icon === "") {
            formValue.icon = "fa-dot-circle";
        }
        const isOverview = e.value.startsWith('ov');
        const isScheduler = e.value.startsWith('sc');
        const isPowerReport = e.value.startsWith('qv');
        const id = Number(e.value.substring(3));
        tempFormValue["overviewId"] = isOverview ? id : null;
        tempFormValue["schedulerId"] = isScheduler ? id : null;
        tempFormValue["powerReportId"] = isPowerReport ? id : null;
        setFormValue(tempFormValue);
        checkChanges();
    };

    const calculateDefaultValue = useCallback(() => {
        //TODO Incomplete, the selected value is never used and there is no initialization of the select box yet
        if (formValue == null || selectOverzicht == null || (formValue.overviewId == null && formValue.schedulerId == null)) {
            return;
        }
        if (formValue.overviewId != null) {
            const item = selectOverzicht.find(item => item.id === `ov_${formValue.overviewId}`);
            return item;
        } else if (formValue.schedulerId != null) {
            const item = selectOverzicht.find(item => item.id === `sc_${formValue.schedulerId}`);
            return item;
        } else if (formValue.powerReportIdId != null) {
            const item = selectOverzicht.find(item => item.id === `qv_${formValue.powerReportId}`);
            return item;
        }

    }, [selectOverzicht, formValue]);

    const handleExitClick = () => {
        if (changeInForm) {

            setThrowNotification(true);
        } else {
            setIsEditing(false);
            setChangeInForm(false);
        }
    };

    const handleDiscardAndContinue = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);

    const handleSave = isCreate ? handleAddNew : handleSaveClick;

    const handleSaveAndContinue = () => {
        handleSave();
    };

    const getSchedulerData = useCallback(async () => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/scheduler/list`
            );
            const data = await response.json();
            setSchedulers(data);
        } catch (error) {
            throw error;
        }
    }, []);

    useEffect(() => { getSchedulerData(); }, [getSchedulerData]);

    const selectedValue = useMemo(calculateDefaultValue, [selectOverzicht, formValue, calculateDefaultValue]);
    return (
        <div className="bl-modal-inner">
            <ModalControls
                handleSaveClick={handleSave}
                modalTitle={"Menu Item"}
                changeInForm={changeInForm}
                hasExitFunction={true}
                handleExitClick={handleExitClick}
            />

            {loading && <Loading />}
            {!loading && (
                <form name="menuItemFrom">
                    <section className="bl-card">
                        <h3>Menu-item</h3>
                        <div className="dx-field">
                            <div className="dx-field-label">
                                Overzicht{" "}

                            </div>
                            <div className="dx-field-value ">

                                <SelectBox
                                    //items={selectOverzicht}
                                    dataSource={dataSource}
                                    grouped={true}
                                    valueExpr="id"
                                    searchEnabled={true}
                                    displayExpr="label"
                                    onValueChanged={handleOverviewChange}
                                />

                            </div>
                        </div>

                        <div className="dx-field">
                            <div className="dx-field-label">Menunaam</div>
                            <div className="dx-field-value">
                                <TextBox
                                    key={formValue.name}
                                    className={`${formValue.name ? "" : "bl-mandatory-field"}`}
                                    defaultValue={formValue.name}
                                    placeholder="Menu naam..."
                                    onValueChanged={(e) => {
                                        if (e.value.trim() !== "") {
                                            e.element.classList.remove("bl-mandatory-field");
                                        } else {
                                            e.element.classList.add("bl-mandatory-field");
                                        }
                                        let tempFormValue = formValue;
                                        tempFormValue["name"] = e.value;
                                        checkChanges();
                                        setFormValue(tempFormValue);
                                    }}
                                    valueChangeEvent="keyup"
                                />
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">
                                Menu icoon{" "}
                                <div className="bl-icon-preview">
                                    <i className={`fas ${selectedIcon}`} />
                                </div>
                            </div>
                            <div className="dx-field-value ">
                                <IconSelect
                                    mandatory={mandatory}
                                    loading={overviewLoading}
                                    dataSource={validatedIcons}
                                    handleValueChange={(e) => {
                                        let tempFormValue = formValue;
                                        tempFormValue["icon"] = e.value;
                                        setFormValue(tempFormValue);
                                        setSelectedIcon(e.value);
                                        checkChanges();
                                    }}
                                    defaultValue={
                                        formValue.icon
                                            ? validatedIcons.find((t) => t.value === formValue.icon)
                                                ? formValue.icon
                                                : "fa-dot-circle"
                                            : "fa-dot-circle"
                                    }
                                />
                            </div>

                        </div>
                    </section>
                </form>
            )}

            {throwNotification && (
                <UserLeave
                    handleCancel={() => setThrowNotification(false)}
                    handleSaveAndContinue={handleSaveAndContinue}
                    handleDiscardAndContinue={handleDiscardAndContinue}
                />
            )}
        </div>
    );
};

export default EDITMENUCONFIG;

import { Column } from "devextreme-react/data-grid";
import { TagBox } from "devextreme-react";
import "../multiple-select.scss";

const onFocusOut = (cell, e) => {
    //cell.setValue(parseInt(e.selectedItem.id));
    console.log("[renderMultipleSelectColumnEdit] onFocusOut", e);
    const flattenedSelection = e.component.option("selectedItems").map(option => option.value).toString();
    cell.setValue(flattenedSelection);
}

const renderMultipleSelectColumnEdit = (cell, options) => {
    const localOnFocusOut = onFocusOut.bind(this, cell);
    return (
        <TagBox
            defaultValue={cell.value == null ? [] : cell.value.split(',')}
            dataSource={options}
            valueExpr="label"
            displayExpr="label"
            showDropDownButton={true}
            showClearButton={true}
            searchEnabled={true}
            showSelectionControls={true}
            hideSelectedItems={true}
            onFocusOut={localOnFocusOut}
        />
    );
};

const renderMultipleSelectCell = (columnData) => {
    let labelArray = [];
    if (columnData.value) {
        labelArray = columnData.data[columnData.column.name.toLowerCase()].split(",");
    }
    return (
        <div className="bl-multiple-keuzenlijst-overview">
            {labelArray.length > 0 &&
                labelArray.map((label, index) => {
                    return (
                        <div className="keuzenlijst-label" key={index}>
                            <div className="label">{label}</div>
                        </div>
                    );
                })}
        </div>
    );
};

const renderMultipleSelectColumn = ({ name, alias, columnWidth, columnId, options, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex, presetFilterValue }) => {
    //console.log("renderMultipleSelectColumn", "presetFilterValue", presetFilterValue);
    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && !Array.isArray(presetFilterValue[0]) && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }

    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];
    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={true}
            allowEditing={!readonly}
            allowFiltering={true}
            width={columnWidth}
            cellRender={renderMultipleSelectCell}
            editCellRender={(cell) => renderMultipleSelectColumnEdit(cell, options)}
            dataType="string"
            mandatory={mandatory}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={false}
            groupIndex={groupIndex}
            filterValue={filterValueOfColumn}
            selectedFilterOperation={operatorOfColumn}
        >
        </Column>
    );
};


export default renderMultipleSelectColumn;

import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import Loading from "../../components/loading/Loading";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Scheduler as DxScheduler, Resource, AppointmentDragging } from 'devextreme-react/scheduler';
import CheckBox from 'devextreme-react/check-box';
import Switch from 'devextreme-react/switch'
import { useGlobalContext } from "../../context/context.js";
import { TagBox } from 'devextreme-react/tag-box';
import QuickEdit from "../modals/EditDetailview/QuickEdit.js";
import QuickDetailviewAdd from "../modals/AddDetailview/quickDetailviewAdd/QuickDetailviewAdd.js";
import "./Scheduler.scss";
import Button from 'devextreme-react/button';
import moment from "moment";
import msalFetch from "../../api/MsalFetch.js";
import appSettings from "../../appsettings.js";
import SchedulerDraggableItems from '../scheduler/SchedulerDraggableItems.js';
import Splitter, { Item } from 'devextreme-react/splitter';
import { Tooltip } from 'devextreme-react/tooltip';
import toast from "react-hot-toast";
import userPlaceholder from "../../assets/images/userPlaceholder.png"


const Scheduler = ({
    title,
    schedulerId,
    value,
    users,
    dataStore,
    selectedUsers,
    setSelectedUsers,
    filterOnOnlineUser,
    usersOfScheduler,
    preferredDetailViewId,
    cellDuration,
    startDayHour,
    endDayHour,
    tableId,
    draggableSelectFieldId,
    draggableItems,
    draggableSelectFieldAlias,
    getDraggableItems,
    draggableDetailViewId,
    defaultAppointmentLength,
}) => {

    const schedulerRef = useRef();
    const [loading, setLoading] = useState(false);
    const [groupByDate, setGroupByDate] = useState(false);
    const [orientation, setOrientation] = useState(false);
    const [groupByDraggableField, setGroupByDraggableField] = useState(false);
    const [editRecordId, setEditRecordId] = useState();
    const [insertRecord, setInsertRecord] = useState(false);
    const [newAppointmentStartDate, setNewAppointmentStartDate] = useState();
    const [newAppointmentEndDate, setNewAppointmentEndDate] = useState();
    const [newAppointmentUserId, setNewAppointmentUserId] = useState();
    const [draggableSelectFieldValue, setDraggableSelectFieldValue] = useState()
    const [draggableDetailViewItemId, setDraggableDetailViewItemId] = useState();
    const groups = ['userId'/*, 'draggableFieldId'*/]
    const UsersForResource = users.filter(user => selectedUsers.includes(user.id));
    const draggingGroupName = ['naam', 'naam2'];

    const onGroupByDateChanged = useCallback((e) => {
        setGroupByDate(e.value);
    }, []);

    const onGroupByDraggableField = useCallback((e) => {
        setGroupByDraggableField(e.value);
    }, []);

    const onUserSelectionChanged = useCallback((e) => {
        if (e.removedItems.length > 0) {
            const removedUsers = e.removedItems.map(item => item.id);
            setSelectedUsers(selectedUsers.filter(item => removedUsers.indexOf(item) === -1));
        } else {
            const addedUsers = e.addedItems.map(item => item.id);
            const uniqueAddedUsers = addedUsers.filter(item => !selectedUsers.includes(item));
            setSelectedUsers((old) => [...old, ...uniqueAddedUsers]);

        }
    }, [setSelectedUsers, selectedUsers]);

    const { isMobile } = useGlobalContext();

    const views = useMemo(() => [
        {
            name: 'Dag', type: 'day', intervalCount: 1, groupOrientation: orientation ? 'vertical' : 'horizontal', startDate: new Date(), maxAppointmentsPerCell: 1,
        },
        {
            name: '3', type: 'day', intervalCount: 3, groupOrientation: orientation ? 'vertical' : 'horizontal', maxAppointmentsPerCell: 1,
        },
        {
            name: 'Week', type: 'week', intervalCount: 1, groupOrientation: orientation ? 'vertical' : 'horizontal', startDate: new Date(), maxAppointmentsPerCell: 1,
        },

        {
            name: '10', type: 'day', intervalCount: 10, groupOrientation: orientation ? 'vertical' : 'horizontal', maxAppointmentsPerCell: 1,
        },
        { name: 'Maand', type: 'month', intervalCount: 1, groupOrientation: orientation ? 'vertical' : 'horizontal' },


    ], [orientation]);

    const onSplitterResizeEnd = useCallback(() => {
        schedulerRef.current.instance().repaint();
    }, []);

    const onAppointmentClick = useCallback((e) => {
        setEditRecordId(e.appointmentData.id);
    }, []);

    const onCellClick = useCallback((e) => {
        const newValueStart = moment.parseZone(e.cellData.startDate).utc(true);
        const newValueEnd = moment.parseZone(e.cellData.startDate.getTime() + defaultAppointmentLength * 60 * 1000).utc(true);
        const newValueStartIso = newValueStart.format('YYYY-MM-DDTHH:mm:ss');
        const newValueEndIso = newValueEnd.format('YYYY-MM-DDTHH:mm:ss');
        setNewAppointmentStartDate(newValueStartIso);
        setNewAppointmentEndDate(newValueEndIso);
        setNewAppointmentUserId(e.cellData.groups.userId)
        setInsertRecord(true);
    }, [defaultAppointmentLength]);

    const itemTemplate = useCallback((e) => {
        const userRecord = users.find((u) => u.id === e.id);
        const styleObj2 = {
            border: "1px solid", borderColor: userRecord.color ? userRecord.color : "#ff9727"
        }

        return <div className="taglist">
            <img style={styleObj2} className="avatarpic" src={userRecord.profilePicSmall ? (userRecord.profilePicSmall) : (userPlaceholder)} alt={`${userRecord.firstname} ${userRecord.name}`} />

            <p>{`${userRecord.firstname} ${userRecord.name}`}</p>
        </div>;
    }, [users]);

    const refreshGrid = useCallback(() => {
        if (schedulerRef.current) {
            schedulerRef.current.instance().getDataSource().reload();
        }
    },
        []);

    useEffect(() => {
        if (!refreshGrid)
            return;
        // setInterval(() => {
        //     refreshGrid();
        // }, 15000);
    }, [refreshGrid]);

    const todayClick = useCallback(() => {
        if (schedulerRef.current) {
            schedulerRef.current.instance().option('currentDate', new Date());
        }
    },
        []);



    const resourceCellComponent = useCallback((e) => {
        const user = users.find(user => user.id === e.data.id);
        const styleObj2 = {
            border: "1px solid", borderColor: user.color ? user.color : "#ff9727"
        }
        return (
            <div className="dx-template-wrapper">
                {groupByDate === false && <><div className="avatar">
                    <img style={styleObj2} className="avatarpic" src={user.profilePicSmall ? (user.profilePicSmall) : (userPlaceholder)} alt={`${user.firstname} ${user.name}`} />
                </div>
                    <div className="name" >
                        <h2>{user.firstname} {user.name}</h2>
                    </div></>
                }
                {groupByDate === true && <>
                    <div className="name" >
                        <h4>{user.firstname} {user.name}</h4>
                    </div></>
                }
            </div >)
            ;
    }, [users, groupByDate]);

    const usersWithNames = useMemo(() => {
        return users.map(user => { return { id: user.id, fullName: `${user.firstname} ${user.name}` } })
    }, [users]);

    console.log("usersWithNames", usersWithNames, users);

    const AppointmentTemplate = useCallback((props) => {
        const { appointmentData } = props.data;
        const draggableFieldColor = props.data.appointmentData.draggableFieldColor;
        let color;
        if (groupByDraggableField) {
            color = draggableFieldColor;
        } else {
            const user = users.find(user => user.id === appointmentData.userId);
            color = user?.color
        }
        const divStyle = {
            backgroundColor: color ?? "#ff9727",
            width: '100%',
            height: '100%',
            //backgroundColor: model.appointmentData.color, // Set background color here
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1 // Place behind other content, if needed
        };

        let appStart = moment(appointmentData.startDate).format('H:mm');
        let appEnd = moment(appointmentData.endDate).format('H:mm');
        console.log("appdata", appointmentData);

        return (

            <>
                <div id={`tooltip_appointment_${appointmentData.id}`} className="appointment" style={divStyle} title={``} >
                    <Tooltip
                        target={`#tooltip_appointment_${appointmentData.id}`}
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                        maxWidth={300}

                    >
                        <div className="app-tooltip" >
                            <strong className="tooltiptitle">{appStart}-{appEnd}</strong><br></br>
                            <strong className="appointmenttitle">{appointmentData.text} </strong>
                            <br></br>
                            {appointmentData.location && (
                                <div>                <i
                                    className="fa-solid fa-location-dot"
                                    style={{ color: appointmentData.draggableFieldColor }} // Use 'color' or another relevant CSS property
                                />     <em>{appointmentData.location}</em>
                                </div>
                            )}
                            {appointmentData.draggableFieldDisplayName && (<div className="draggableinfo">
                                <i
                                    className="fa-solid fa-square coloricon"
                                    style={{ color: appointmentData.draggableFieldColor }} // Use 'color' or another relevant CSS property
                                />
                                <strong> {appointmentData.draggableFieldDisplayName}</strong>
                            </div>)}
                            <div className="description" dangerouslySetInnerHTML={{ __html: appointmentData.description }}></div>
                        </div>
                    </Tooltip>

                    <div className="title">{appointmentData.text} </div>
                    <div className="buttons">
                        {appointmentData.draggableFieldItemId && draggableDetailViewId && (<div className="location">
                            <button
                                style={{ all: 'unset', }}
                                onClick={(e) => setDraggableDetailViewItemId(appointmentData.draggableFieldItemId)}
                                title={draggableSelectFieldAlias}
                                className="app-button"
                            >
                                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                            </button>
                        </div>)}
                        {appointmentData.location && <div className="app-button"><a href={`https://maps.google.com/maps?q=${appointmentData.location}`} target="blank" title="Bekijk adres in Google Maps"><i class="fa-solid fa-location-dot"></i></a></div>}
                    </div>
                </div >
            </>
        );
    }, [draggableSelectFieldAlias, users, groupByDraggableField, draggableDetailViewId],);

    const handleSave = useCallback((recordId) => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        setEditRecordId(recordId); // open edit
        refreshGrid();
    }, [refreshGrid]);

    const handleUserLeaveContinue = useCallback(() => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);

    const handleSaveAndCreateNew = useCallback(() => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        // close the first, wait a milisecond for the Content to unmount, re-mount a fresh Content for fresh data fetching
        setTimeout(() => {
            setInsertRecord(true);
        }, 1);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);


    const handleClose = useCallback(() => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);

    const handleUserLeaveSave = useCallback(() => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);

    const handleSaveEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);

    const handleSaveAndCreateNewEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
        setTimeout(() => {
            setInsertRecord(true);
        }, 1);
    }, [refreshGrid]);

    const handleDraggableItemSaveAndCreateNewEdit = useCallback(() => {

        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setDraggableSelectFieldValue(null);
        getDraggableItems();
        // setTimeout(() => {
        //     setInsertRecord(true);
        // }, 1);
    }, [refreshGrid, getDraggableItems]);

    const handleUserLeaveContinueEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
    }, [refreshGrid])

    const handleDraggableItemLeaveContinueEdit = useCallback(() => {

        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setDraggableDetailViewItemId(null);
    }, [refreshGrid])

    const handleCloseEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
    }, [refreshGrid]);

    const handleDraggableItemEdit = useCallback(() => {

        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setDraggableDetailViewItemId(null);
        getDraggableItems();
    }, [refreshGrid, getDraggableItems]);


    const handleUserLeaveSaveEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
    }, [refreshGrid]);

    const handleDraggableItemSaveEdit = useCallback(() => {

        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setDraggableDetailViewItemId(null);
        debugger;
        getDraggableItems();
    }, [refreshGrid, getDraggableItems]);

    const handleDeleteEdit = useCallback(() => {
        const toastId = toast.loading(`Item wordt verwijderd`);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
        toast.success(`Item verwijderd!`, { id: toastId });
    }, [refreshGrid]);

    const handleDraggableItemDeleteEdit = useCallback(() => {
        const toastId = toast.loading(`Item wordt verwijderd`);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setDraggableDetailViewItemId(null);
        toast.success(`Item verwijderd!!`, { id: toastId });
    }, [refreshGrid]);


    const dropItem = useCallback(async (draggableItemId, userId, startDateTime, endDateTime) => {


        try {
            const params = {
                userId: userId,
                draggableItemId: draggableItemId,
                startDateTime: startDateTime,
                endDateTIme: endDateTime
            }
            await msalFetch(null,
                `${appSettings.api.endpoint}/api/scheduler/${schedulerId}/drop-item`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(params),
                }
            );

        } catch (error) {
            throw error;
        }
    }, [schedulerId])

    const onDraggableDrop = useCallback(async (e) => {

        const dateStartDate = new Date(e.itemData.startDate);
        const dateEndDate = new Date((new Date(e.itemData.startDate)).getTime() + (defaultAppointmentLength * 60 * 1000));
        const parsedDateStart = `${dateStartDate.getFullYear()}/${String(dateStartDate.getMonth() + 1).padStart(2, '0')}/${String(dateStartDate.getDate()).padStart(2, '0')} ${String(dateStartDate.getHours()).padStart(2, '0')}:${String(dateStartDate.getMinutes()).padStart(2, '0')}`;
        const parsedDateEnd = `${dateEndDate.getFullYear()}/${String(dateEndDate.getMonth() + 1).padStart(2, '0')}/${String(dateEndDate.getDate()).padStart(2, '0')} ${String(dateEndDate.getHours()).padStart(2, '0')}:${String(dateEndDate.getMinutes()).padStart(2, '0')}`;

        await dropItem(e.fromData.primairyKeyValue, e.itemData.userId, parsedDateStart, parsedDateEnd);
        // toast.dismiss();
        // toast.success(`Item gepland`);
        refreshGrid();
    }, [dropItem, refreshGrid])

    const draggableDropAction = useCallback(async (e) => {
        const myPromise = onDraggableDrop(e);
        toast.promise(myPromise, {
            loading: 'Afspraak toevoegen...',
            success: 'Afspraak toegevoegd',
            error: 'Fout tijdens toevoegen afspraak',

        });
        //bloom.promise(myPromise);
    }, [onDraggableDrop])


    const onContentReady = useCallback(async (e) => {
        var scheduler = e.component;
        var currentDate = scheduler.getStartViewDate();
        currentDate.setHours(startDayHour, 0, 0);

        e.component.scrollToTime(startDayHour, 0);
    }, [startDayHour]);

    const [isExecuting, setIsExecuting] = useState(false);

    const updateDraggableItems = useCallback(() => {
        setIsExecuting(true);
        getDraggableItems();

        toast.success("Lijst is vernieuwd");
        setIsExecuting(false);
    }, [getDraggableItems]);

    const onAppointmentRendered = (e) => {
        const width = e.element.querySelector('.dx-scheduler-date-table-cell').clientWidth; // get a cell's width
        e.appointmentElement.style.width = `${width - 10}px`;
    };




    if (!loading) {
        return (
            <>

                <Splitter
                    onResizeEnd={onSplitterResizeEnd}
                    onItemCollapsed={onSplitterResizeEnd}

                    onItemExpanded={onSplitterResizeEnd}


                >
                    <Item
                        size={draggableSelectFieldId ? "75%" : "100%"}
                        resizable={true}
                        collapsible={false}

                    >
                        <header className="bloom-list-header">
                            <h3 className="listHeader">{`${title}`}</h3>
                        </header>
                        <div className="scheduleroptions">
                            <div className="schedulerusers">
                                {selectedUsers && (
                                    <TagBox
                                        defaultValue={selectedUsers}
                                        items={users}
                                        selectAllMode="allPages"
                                        showSelectionControls={true}
                                        showClearButton={true}
                                        searchEnabled={true}
                                        showDropDownButton={true}
                                        valueExpr="id"

                                        multiline={false}
                                        displayExpr="firstname"
                                        itemRender={itemTemplate}
                                        maxDisplayedTags={2}
                                        showMultiTagOnly={false}
                                        onSelectionChanged={onUserSelectionChanged}
                                    />
                                )}</div>
                            <div className="schedulerbuttons">
                                <Button
                                    stylingMode="outlined"
                                    cssStyle="schedulerbutton"
                                    type="default"
                                    hint={"Ververs agenda"}
                                    disabled={isExecuting}
                                    icon={`fas ${isExecuting ? 'fa-pulse fa-spinner' : "fa-refresh"}`}
                                    onClick={refreshGrid}
                                />
                                <Button
                                    stylingMode="outlined"
                                    className="schedulerbutton"
                                    type="default"
                                    hint={"Terug naar vandaag"}
                                    disabled={isExecuting}
                                    icon={`fas ${isExecuting ? 'fa-pulse fa-spinner' : "fa-calendar"}`}
                                    onClick={todayClick}
                                />

                                {!isMobile && (<>
                                    <Switch
                                        className="scheduleroption"
                                        switchedOffText="User kleur"
                                        switchedOnText={`${draggableSelectFieldAlias} kleur`}
                                        defaultValue={groupByDraggableField}
                                        onValueChanged={onGroupByDraggableField}
                                        width={80}
                                    />

                                    <Switch
                                        className="scheduleroption"
                                        switchedOffText="User prio"
                                        switchedOnText="Datum prio"
                                        defaultValue={groupByDate}
                                        onValueChanged={onGroupByDateChanged}
                                        width={80}
                                    />
                                </>)}
                            </div>
                        </div>

                        <div className="schedulercontent">
                            <DxScheduler
                                className={`cellduration${cellDuration}`}
                                remoteFiltering={true}
                                showCurrentTimeIndicator={true}
                                shadeUntilCurrentTime={true}
                                defaultCurrentView="week"
                                showAllDayPanel={false}
                                startDayHour={/*startDayHour*/0}
                                endDayHour={endDayHour}
                                id={`scheduler_${schedulerId}`}
                                ref={schedulerRef}
                                views={views}
                                height={"calc(100vh - 300px)"}
                                adaptivityEnabled={isMobile}

                                dataSource={dataStore}
                                groupByDate={groupByDate}
                                allDayPanelMode="allDay"
                                cellDuration={cellDuration}
                                groups={groups}
                                onAppointmentDblClick={onAppointmentClick}
                                appointmentComponent={AppointmentTemplate}
                                onCellClick={onCellClick}
                                onAppointmentRendered={onAppointmentRendered}
                                resourceCellComponent={resourceCellComponent}
                                onContentReady={onContentReady}
                            >
                                {<Resource
                                    fieldExpr="userId"
                                    dataSource={UsersForResource}
                                    allowMultiple={false}
                                    displayExpr="firstname"
                                    label="firstname"
                                    // colorExpr="color"
                                    useColorAsDefault={false}

                                />}
                                {draggableSelectFieldId && groupByDraggableField &&
                                    <Resource
                                        fieldExpr="draggableFieldId"
                                        dataSource={draggableItems}
                                        allowMultiple={false}
                                        displayExpr="label"
                                        label="label"
                                    // colorExpr="draggableFieldColor"
                                    //useColorAsDefault={true}

                                    />
                                }
                                <AppointmentDragging
                                    group={draggingGroupName}
                                    onRemove={(e) => { console.log("AppointmentDragging - onRemove", e) }}
                                    onAdd={draggableDropAction}
                                />
                            </DxScheduler>
                        </div>
                    </Item>
                    {draggableSelectFieldId && !isMobile &&
                        <Item
                            resizable={true}
                            collapsible={true}
                        >
                            {draggableSelectFieldId && !isMobile &&
                                <div>
                                    <div className="draggable-header">
                                        <header className="bloom-list-header">
                                            <h3 className="listHeader">{`${draggableSelectFieldAlias}`}</h3>
                                        </header>
                                        {/*
                                    <Button
                                        stylingMode="outlined"
                                        type="default"
                                        hint={"Ververs deze lijst"}
                                        disabled={isExecuting}
                                        icon={`fas ${isExecuting ? 'fa-pulse fa-spinner' : "fa-refresh"}`}
                                        onClick={updateDraggableItems}
                                    /> */}
                                    </div>
                                    {draggableItems &&
                                        <SchedulerDraggableItems
                                            draggingGroupName={draggingGroupName}
                                            draggableItems={draggableItems}
                                            title={draggableSelectFieldAlias}
                                            getDraggableItems={getDraggableItems}
                                            draggableDetailViewId={draggableDetailViewId}
                                            setDraggableDetailViewItemId={setDraggableDetailViewItemId}
                                        />}
                                </div>}

                        </Item>}
                </Splitter >

                {
                    editRecordId &&
                    <QuickEdit
                        targetId={editRecordId}
                        isRelation={false}
                        setIsEditing={setEditRecordId}
                        isEditing={true}
                        currentOverview={{ preferredDetailViewId: preferredDetailViewId }}
                        refreshGrid={refreshGrid}
                        controlRelationId={null}
                        overrideDetailViewId={null}
                        handleSave={handleSaveEdit}
                        handleSaveAndCreateNew={handleSaveAndCreateNewEdit}
                        handleUserLeaveContinue={handleUserLeaveContinueEdit}
                        handleClose={handleCloseEdit}
                        handleUserLeaveSave={handleUserLeaveSaveEdit}
                        handleDelete={handleDeleteEdit}
                    />
                }
                {draggableDetailViewItemId && <QuickEdit
                    targetId={draggableDetailViewItemId}
                    isRelation={false}
                    setIsEditing={setDraggableDetailViewItemId}
                    isEditing={true}
                    currentOverview={{ preferredDetailViewId: draggableDetailViewId }}
                    refreshGrid={refreshGrid}
                    controlRelationId={null}
                    overrideDetailViewId={null}
                    handleSave={handleDraggableItemSaveEdit}
                    handleSaveAndCreateNew={handleDraggableItemSaveAndCreateNewEdit}
                    handleUserLeaveContinue={handleDraggableItemLeaveContinueEdit}
                    handleClose={handleDraggableItemEdit}
                    handleUserLeaveSave={handleDraggableItemSaveEdit}
                    handleDelete={handleDraggableItemDeleteEdit}
                />
                }
                {
                    insertRecord && newAppointmentStartDate && newAppointmentUserId && newAppointmentEndDate &&
                    <QuickDetailviewAdd
                        setOpenQuickAddModal={setInsertRecord}
                        openQuickAddModal={insertRecord}
                        refreshGrid={refreshGrid}
                        controlRelationId={null}
                        targetId={preferredDetailViewId}
                        connectorStartDate={newAppointmentStartDate}
                        connectorEndDate={newAppointmentEndDate}
                        connectorUserId={newAppointmentUserId}
                        handleSaveAndCreateNew={handleSaveAndCreateNew}
                        handleSave={handleSave}
                        handleUserLeaveContinue={handleUserLeaveContinueEdit}
                        handleClose={handleClose}
                        handleUserLeaveSave={handleUserLeaveSave}
                        draggableSelectFieldId={draggableSelectFieldId}
                        draggableSelectFieldValue={draggableSelectFieldValue}
                    />
                }

            </>
        );
    } else {
        return <Loading />;
    }
};

export default Scheduler;

import { useGraphContext } from "../graph/context.js";
import { confirm } from 'devextreme/ui/dialog';
import "../notifications/forbidden/forbidden.scss"; // why is this here?
import React, { useCallback, useState, useRef, useMemo, useEffect } from "react";
import appSettings from "../../appsettings.js";
import msalFetch from "../../api/MsalFetch.js";
import { useGlobalContext } from "../../context/context.js";
import ButtonGroup, { Item as ButtonGroupItem } from 'devextreme-react/button-group';
import { TextBox, CheckBox, TextArea, Lookup } from "devextreme-react";
import toast from "react-hot-toast";
import QuickEdit from "../modals/EditDetailview/QuickEdit";
import DuplicateDetailview from "../modals/DuplicateDetailview/DUPLICATEDETAILVIEW";
import QuickDetailviewAdd from "../modals/AddDetailview/quickDetailviewAdd/QuickDetailviewAdd";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { useHistory } from "react-router-dom";
import { getFormatter } from '../formats.js';
import DataSource from 'devextreme/data/data_source';
import DropDownButton from 'devextreme-react/drop-down-button';
import { DropDownOptions } from "devextreme-react/lookup";
import CustomStore from "devextreme/data/custom_store"
import {
    DataGrid,
    Column,
    FilterRow,
    FilterPanel,
    FilterBuilderPopup,
    SearchPanel,
    Scrolling,
    LoadPanel,
    ColumnFixing,
    ColumnChooser,
    Position,
    Grouping,
    GroupPanel,
    GroupItem,
    Summary,
    TotalItem,
    KeyboardNavigation,
    HeaderFilter,
    Toolbar,
    Item,
    Paging,
    Pager,
    Sorting,
    Editing,
    Texts,
    MasterDetail,
    Button as DataGridButton,
    RowDragging,
    StateStoring,
    RemoteOperations,
} from "devextreme-react/data-grid";
import { infoText } from '../../global/paging.js';
import renderColumn from './columns/index';
import "../mastergrid.scss";
import "./List.scss";
import "./parts.scss";
import { Resizable } from "devextreme-react";
import EditHtmlField from '../Popups/EditHtmlField.js';
import { ColumnDisplayValueCalculated } from "../../enums/columnDisplayValue.js";
import { ColumnValidationCurrency } from "../../enums/columnValidation.js";
import { Popup } from 'devextreme-react/popup';

const List = ({
    overviewId,
    controlRelationId,
    dataSource,
    columns,
    scrollHorizontal,
    columnFunctions,
    tableId,
    isControlRelation,
    expandDetailViewId,
    manualSortColumnId,
    detailViewIdAdd,
    tableItemId,
    excelFilename,
    overviewOrControlRelationName,
    preferredDetailViewId,
    preferredDetailViewIdField,
    preferredDetailViewIdEdit,
    setInlineEditMode,
    inlineEditMode,
    cloneRecord,
    keyExpression,
    externalSelectFunction,
    allowInlineEditing,
    verticalHeight,
    autoRefresh,
    allowCopy,
    allowDelete,
    allowCreate,
    allowRead,
    allowUpdate,
    allowExportExcel,
    duplicateData,
    setDuplicateData,
    gridName,
    hasFilter,
}) => {
    const { userLoading, currentUser } = useGraphContext();
    const [presetFilterValue, setPresetFilterValue] = useState(null);

    const [presetFilterId, setPresetFilterId] = useState();
    const allowedPageSizes = [50, 250, 1000];
    const storeStateValue = `stateStorage`;
    const [popupEditRecordId, setPopupEditRecordId] = useState(null);
    const [showPopupAdd, setShowPopupAdd] = useState(null);
    const [selectedHtmlTableItemId, setSelectedHtmlTableItemId] = useState(null);
    const [storeState, setStoreState] = useState(/* localStorage.storeState !== 'false' */localStorage.getItem(storeStateValue) === 'true');
    const [showPopupAddPresetFilter, setShowPopupAddPresetFilter] = useState(false);
    const [showPopupSelectPresetFilter, setShowPopupSelectPresetFilter] = useState(false);
    const [presetFiltersForLookup, setPresetFiltersForLookup] = useState();
    const defaultFormAddFilter = useMemo(() => {
        return {
            limitedToUser: true,
            title: null,
            description: null
        }
    }, []);
    const [formAddFilter, setFormAddFilter] = useState(defaultFormAddFilter)

    const { isMobile } = useGlobalContext();
    const dataGridRef = useRef();

    const { push } = useHistory();


    const elementAttr = {
        id: isControlRelation ? "relationGridContainer" : "gridContainer",
    };
    const statePrefix = 'stateStorage_';
    const localStorageKey = `${statePrefix}${isControlRelation ? 'cr' : 'ov'}_${isControlRelation ? controlRelationId : overviewId}`;

    const getCascadingParentId = useCallback((columnId) => {
        const selectedRecord = dataGridRef.current.instance().getSelectedRowsData()[0];
        const column = columns.find(col => col.id === columnId);
        const cascadingParentColumn = columns.find(col => col.id === column.cascadingParentColumnId)
        let cascadingValue = null;
        if (cascadingParentColumn != null) {
            const cascadingParentColumnName = cascadingParentColumn.name.toLowerCase();
            cascadingValue = selectedRecord[cascadingParentColumnName + '_id'];
            return cascadingValue;
        }
    }, [columns]);

    const renderColumns = (columns, datasources, manualSortColumnId, scrollHorizontal, isMobile, refreshGrid) => {
        const columnsWithoutIdOrSortcolumn = columns.filter(column => column.name.toLowerCase() !== 'id' && (manualSortColumnId === null || column.id !== manualSortColumnId));
        const totalWidth = columnsWithoutIdOrSortcolumn.reduce((accumulator, currentValue) => accumulator + currentValue.width, 0);
        return columnsWithoutIdOrSortcolumn.map((column, index) => {
            const {
                id: columnId,
                name,
                alias,
                displayFormat,
                cascadingParentColumnId,
                columnTypeCode,
                options,
                width,
                readonly,
                displayValueId,
                inputColorColumnId,
                mandatory,
                actionId,
                table: { id: tableId },
                columnTypeCodeOfDependColumn,
                columnValidationId,
                sortIndex,
                sortDirection,
                groupIndex,
                extraField1: strExtraField1,
                typeAheadCharacters,
                imageLinkColumnId,
                groupColumnId,
            } = column;
            //to destructuring once figured out
            const actionType = column.action == null ? null : column.action.actionType;
            const oneTime = column.action == null ? null : column.action.oneTime;
            const actionRefresh = column.action == null ? null : column.action.refresh;
            const actionIcon = column.action == null ? null : column.action.icon;
            let columnWidth = 0;
            if (isMobile) {
                columnWidth = `${(width * 50)}px`;
            }
            else {
                if (scrollHorizontal) {
                    columnWidth = `${(width * 100)}px`;
                }
                else {
                    columnWidth = `${(width / totalWidth) * 100}%`;
                }
            }
            return renderColumn({
                columnId,
                columnTypeCode,
                name,
                alias,
                columnWidth,
                displayValueId,
                readonly,
                mandatory,
                options,
                sortIndex,
                sortDirection,
                displayFormat,
                inputColorColumnId,
                //datasources,
                actionId,
                actionType,
                oneTime,
                tableId,
                actionRefresh,
                refreshGrid,
                actionIcon,
                columnTypeCodeOfDependColumn,
                inlineEditMode,
                overviewId,
                columnValidationId,
                groupIndex,
                strExtraField1,
                typeAheadCharacters,
                getCascadingParentId: cascadingParentColumnId === null ? null : getCascadingParentId,
                columnHasImage: imageLinkColumnId !== null,
                columnHasGroup: groupColumnId !== null,
                presetFilterValue,
            }
            );
        });
    };




    const selectFilterPreselection = useCallback((id, filter) => {
        setPresetFilterId(id);
        setPresetFilterValue(filter == null ? null : JSON.parse(filter));
        setShowPopupSelectPresetFilter(false);
    }, []);

    const onPresetFilterSelectionChanged = useCallback((e) => {
        selectFilterPreselection(e.row.data.id, e.row.data.filter);
        setShowPopupSelectPresetFilter(false);
    }, [selectFilterPreselection]);

    const onPresetFilterRowClick = useCallback((e) => {
        console.log("onPresetFilterRowClick", e);
        selectFilterPreselection(e.data.id, e.data.filter);
        setShowPopupSelectPresetFilter(false);
    }, [selectFilterPreselection]);

    const onPresetFilterInitNewRow = useCallback((e) => {
        console.log("onPresetFilterInitNewRow", e);
        e.data = {
            ...e.data,
            limitedToUser: !currentUser?.admin,
            filter: JSON.stringify(presetFilterValue)
        };
    }, [currentUser, presetFilterValue]);

    const updatePresetFilterOrder = useCallback(async (e) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/overview/reorder-overview-filter/${e.itemData.id}/${e.itemData.overviewId}/${e.fromIndex}/${e.toIndex}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" }
                }
            );
            if (res.ok) {

            } else {
                toast.error("Oeps er ging even iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        } finally {
            e.component.getDataSource().reload();
        }
    }, [])

    const onPresetFilterUdateFilter = useCallback(async (e) => {
        if (!await confirm("Weet u zeker dat u deze filterinstelling wilt bijwerken met de huidige selectie?", "Filters bijwerken"))
            return;
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/overview/update-overview-filter/${e.row.data.id}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({ filter: JSON.stringify(presetFilterValue) })
                }
            );
            if (res.ok) {
                toast.success("De filter is bijgewerkt");
            } else {
                toast.error("Oeps er ging even iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        } finally {
            //refreshGrid();
        }
    }, [presetFilterValue])

    const refreshGrid = useCallback(() => {
        // Pass true to repaint updated data rows; false to repaint all data rows.
        dataGridRef.current.instance().refresh(true);
    }, []);

    const renderedColumns = renderColumns(
        columns,
        null, //datasources,
        manualSortColumnId,
        scrollHorizontal,
        isMobile,
        refreshGrid,
    );

    const onCloneClick = useCallback((...args) => {
        cloneRecord(...args);
    }, [cloneRecord]);

    const onEditClick = useCallback((e) => {
        setPopupEditRecordId(e.row.key);
    }, []);

    const redirectToDetailView = useCallback((key, openNewWindow) => {
        if (preferredDetailViewId != null) {
            if (openNewWindow) {
                const win = window.open(`/detail/${preferredDetailViewId}/${key}`, "_blank");
                win.focus();
            } else {
                push(`/detail/${preferredDetailViewId}/${key}`);
            }
        }
    }, [preferredDetailViewId, push]);

    const onRowSelect = useCallback((e) => {
        redirectToDetailView(e.row.data[preferredDetailViewIdField] ?? e.row.key, e.event.ctrlKey)
    }, [redirectToDetailView, preferredDetailViewIdField]);

    const setOpenQuickAddModal = useCallback((bool) => {
        setShowPopupAdd(bool);
    }, []);

    const onCellClick = useCallback((e) => {
        if (e.rowType !== 'data' || ["detailExpand", "buttons"].includes(e.column.type)) {
            return null;
        }

        let columnName = e.column.dataField.toLowerCase();
        if (columnName.endsWith('_id') && columns.map(col => col.name.toLowerCase()).includes(columnName.slice(0, -3))) {
            columnName = columnName.slice(0, -3);
        }
        let column;
        if (e.column.columnId != null) {
            column = columns.find(col => col.id === e.column.columnId);
        } else {
            //probably redundant, the columns should have their columnIds
            column = columns.find(col => col.name.toLowerCase() === columnName);
        }

        if (column.columnTypeCode === 'HTMLVIEWER' || column.columnTypeCode === 'HTMLEDITOR' || column.columnTypeCode === 'HTMLEDITORADVANCED') {
            if (inlineEditMode || e.value) {
                setSelectedHtmlTableItemId({
                    rowIndex: e.rowIndex,
                    columnIndex: e.columnIndex,
                    alias: column.alias,
                    tableItemId: e.data.id,
                    columnId: e.column.columnId,
                    isHtmlViewer: column.columnTypeCode === 'HTMLVIEWER',
                    isHtmlEditor: column.columnTypeCode === 'HTMLEDITOR',
                    isHtmlEditorAdvanced: column.columnTypeCode === 'HTMLEDITORADVANCED',
                    refreshFunction: refreshGrid,
                    dataGridRef: dataGridRef,
                });
            }
            return null;
        }
        if (inlineEditMode) {
            dataGridRef.current.instance().selectRows([e.key]);
            return null;
        } else {
            if (column.columnTypeCode === 'FILE' || column.columnTypeCode === 'FTP' || column.columnTypeCode === 'ACTION') {
                e.cancel = true;
            } else {
                redirectToDetailView(e.data[preferredDetailViewIdField] ?? e.key, e.event.ctrlKey);
            }
        }
    }, [columns, inlineEditMode, refreshGrid, dataGridRef, preferredDetailViewIdField, redirectToDetailView]);

    const inlineEditModeClick = useCallback(() => {
        setInlineEditMode(!inlineEditMode);
    }, [inlineEditMode, setInlineEditMode]);

    const storeStateClick = useCallback(() => {
        const newValue = !storeState;
        const currentInternalState = dataGridRef?.current?.instance().state();
        localStorage.setItem(storeStateValue, newValue);
        if (newValue) {
            localStorage.setItem(localStorageKey, JSON.stringify(currentInternalState));
        } else {
            const itemsToRemove = Object.keys(localStorage).filter(item => item.startsWith(statePrefix));
            for (var c = 0; c < itemsToRemove.length; c++) {
                localStorage.removeItem(itemsToRemove[c]);
            }
            dataGridRef?.current?.instance().state(null);
        }

        setStoreState(newValue);
    }, [storeState, setStoreState, localStorageKey, storeStateValue]);

    const showPopupAddClick = useCallback(() => {
        setShowPopupAdd(true);
    }, []);

    const goToAdd = useCallback((e) => {
        e.event.preventDefault();
        let url = `/detail/${detailViewIdAdd}`;
        if (isControlRelation) {
            url += `?parentTableitemId=${tableItemId}&parentControlRelationId=${controlRelationId}`;
        }
        push(url);
    }, [push, detailViewIdAdd, isControlRelation, tableItemId, controlRelationId]);

    const clearFilterClick = useCallback(() => {
        console.log("clearFilterClick", presetFilterValue);
        if (presetFilterId == null) {
            console.log("clearFilterClick - presetFilterId == null");
            //dataGridRef?.current?.instance().state(null);
            setPresetFilterValue(null);
        } else {
            setPresetFilterId(null);
            setPresetFilterValue(null);
        }

    }, [presetFilterId, presetFilterValue]);

    const refreshClick = useCallback(() => {
        refreshGrid();
    }, [refreshGrid]);

    const renderMasterDetail = useCallback((columnData) => {
        return (
            <div className="inline-detailview-container">
                <QuickEdit
                    targetId={columnData.data.key} // @TODO keyExpression
                    setIsEditing={() => {
                        dataGridRef.current.instance().collapseAll(-1);
                    }}
                    refreshGrid={refreshGrid}
                    isRelation={null}
                    controlRelationId={null}
                    isMasterDetail
                    expandDetailviewId={expandDetailViewId}
                />
            </div>
        );

    }, [
        dataGridRef,
        expandDetailViewId,
        refreshGrid,
    ]);

    const exportHandler = useCallback((e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(overviewOrControlRelationName);

        exportDataGrid({
            component: dataGridRef.current.instance(),
            worksheet: worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            loadPanel: {
                enabled: true,
                shading: true,
                text: `Excel van ${overviewOrControlRelationName} wordt gemaakt...`,
                shadingColor: "#808080"
            }
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), excelFilename);
            });
        });
        e.cancel = true;
    }, [overviewOrControlRelationName, excelFilename]);


    const exportPDFHandler = useCallback((e) => {
        const doc = new jsPDF();
        const dataGrid = dataGridRef.current.instance();
        exportDataGridToPdf({
            jsPDFDocument: doc,
            indent: 5,
            component: dataGrid,
            loadPanel: {
                enabled: true,
                shading: true,
                text: `PDF van ${overviewOrControlRelationName} wordt gemaakt...`,
                shadingColor: "#808080"
            }
        }).then(() => {
            doc.save(`${overviewOrControlRelationName}.pdf`);
        });
        e.cancel = true;
    }, []);

    const getFormat = useCallback((item) => {
        const column = columns.find((t) => t.id === item.columnId);
        const columnTypeCodeToRender = column.columnTypeCodeOfDependColumn ?? column.columnTypeCode;
        const { columnValidationId, displayValueId } = column;
        let format;

        if (columnTypeCodeToRender === 'EU') {
            format = getFormatter(columnValidationId === ColumnValidationCurrency.Precision2 ? 'EURO2' : 'EURO3');
        } else if (columnTypeCodeToRender === 'DEC') {
            format = getFormatter(column.displayFormat)
        } else if (columnTypeCodeToRender === 'NUM') {
            //format = ',##0'
        } else if (columnTypeCodeToRender === 'PERC') {
            format = getFormatter('PERC');
        } else if (columnTypeCodeToRender === 'CALCULATED') {
            switch (displayValueId) {
                case ColumnDisplayValueCalculated.NUM:
                    {
                        break;
                    }
                case ColumnDisplayValueCalculated.Precision1:
                    {
                        format = getFormatter('1')
                        break;
                    }
                case ColumnDisplayValueCalculated.Precision2:
                    {
                        format = getFormatter('2')
                        break;
                    }
                case ColumnDisplayValueCalculated.Precision3:
                    {
                        format = getFormatter('3');
                        break;
                    }
                case ColumnDisplayValueCalculated.EUPrecison2:
                    {
                        format = getFormatter('EURO2')
                        break;
                    }
                case ColumnDisplayValueCalculated.EUPrecison3:
                    {
                        format = getFormatter('EURO3')
                        break;
                    }
                default:
            }
        } else {
            format = null;
        }
        return format;
    }, [columns]);

    const renderedSummaries = useMemo(() => {
        return columnFunctions?.length > 0 && columns.length > 0 && (
            <Summary recalculateWhileEditing={false}>
                {columnFunctions.map((item, index) => {
                    const column = columns.find((t) => t.id === item.columnId);
                    let columnName = column.name.toLowerCase();
                    const format = getFormat(item);

                    return [
                        <TotalItem
                            key={item.columnId}
                            column={columnName}
                            summaryType={item.function.toLowerCase()}
                            valueFormat={format}


                        />,
                        <GroupItem
                            key={item.columnId}
                            column={columnName}
                            alignByColumn
                            valueFormat={format}
                            summaryType={item.function.toLowerCase()}

                        />
                    ];

                })}

            </Summary>
        )
    }, [columnFunctions, columns, getFormat]);

    const onOptionChanged = useCallback((e) => {
        if (e.name === "filterValue") {
            console.log("onOptionChanged", e);
            //setFilterValue(e.value);
            setPresetFilterValue(e.value);
        }
    }, []);

    const onEditorValueChanged = useCallback((e) => {
        console.log("onEditorValueChanged", e);
    }, [])

    const updateGridOrder = useCallback(async (e) => {
        const sortFieldName = columns.find(c => c.id === manualSortColumnId).name.toLowerCase();
        const sortIndex = e.component.getVisibleRows()[e.toIndex].data[sortFieldName]
        const fromSortFieldValue = e.itemData[sortFieldName];
        const reorderedTableItemId = e.itemData.id;
        const difference = sortIndex - fromSortFieldValue;

        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/table/reorder/${tableId}/${reorderedTableItemId}/${manualSortColumnId}/${fromSortFieldValue}/${fromSortFieldValue + difference}/${overviewId}/${controlRelationId}/${tableItemId}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" }
                }
            );
            if (res.ok) {

            } else {
                toast.error("Oeps er ging even iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        } finally {
            refreshGrid();
        }
    }, [columns, manualSortColumnId, tableId, refreshGrid, overviewId, controlRelationId, tableItemId]);

    const stylObj = { height: isControlRelation ? (verticalHeight ?? 40) + 'vh' : "calc(100vh - 165px)" };

    const hasFileOrFtpColumn = columns.some(({ columnTypeCode }) => ['FILE', 'FTP'].includes(columnTypeCode));
    const onRowPrepared = useCallback((e) => {
        if (!hasFileOrFtpColumn) {
            return;
        }
        if (e.rowType === 'data') {
            e.rowElement.style.height = "115px"; // image column size
        }
    }, [hasFileOrFtpColumn]);

    const handleSaveAndCreateNew = useCallback(() => {
        refreshGrid();
        setShowPopupAdd(false);
        // close the first, wait a milisecond for the Content to unmount, re-mount a fresh Content for fresh data fetching
        setTimeout(() => {
            setShowPopupAdd(true);
        }, 1);
    }, [setShowPopupAdd, refreshGrid]);

    const handleSave = useCallback((recordId) => {
        refreshGrid();
        setShowPopupAdd(false);
        setPopupEditRecordId(recordId);
    }, [refreshGrid]);

    const handleAddNew = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setShowPopupAdd(false);
        // close the first, wait a milisecond for the Content to unmount, re-mount a fresh Content for fresh data fetching
        setTimeout(() => {
            setShowPopupAdd(true);
        }, 1);
    }, []);

    const handleUserLeaveContinue = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setShowPopupAdd(false);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid, setShowPopupAdd, setDuplicateData]);

    const handleClose = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setShowPopupAdd(false);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid, setShowPopupAdd, setDuplicateData]);

    const handleUserLeaveSave = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setShowPopupAdd(false);
        refreshGrid();
    }, [refreshGrid, setShowPopupAdd, setDuplicateData]);

    const handleSaveAndCreateNewEdit = useCallback(() => {
        refreshGrid();
        setDuplicateData(null);
        setPopupEditRecordId(null);
        setTimeout(() => {
            setShowPopupAdd(true);
        }, 1);
    }, [refreshGrid, setDuplicateData]);

    const handleSaveEdit = useCallback(() => {
        refreshGrid();
    }, [refreshGrid]);

    const handleUserLeaveContinueEdit = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        refreshGrid();
    }, [refreshGrid, setDuplicateData]);

    const handleCloseEdit = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(null);
        refreshGrid();
    }, [refreshGrid]);

    const handleUserLeaveSaveEdit = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(false);
        refreshGrid();
    }, [refreshGrid, setPopupEditRecordId]);

    const handleDeleteEdit = useCallback(() => {
        setDuplicateData(null);
        setPopupEditRecordId(false);
        refreshGrid();
    }, [refreshGrid]);

    const onShowPopupAddPresetFilterClick = useCallback((e) => {
        setShowPopupAddPresetFilter(true);
    }, [])

    const onShowPopupSelectPresetFilterClick = useCallback((e) => {
        setShowPopupSelectPresetFilter(true);
    }, [])

    const deletePresetFilter = useCallback(async (e) => {
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/delete-overview-filter/${e}`, {
                method: "DELETE",
                headers: { "Content-type": "application/json" }
            });
            if (res.ok) {
                toast.success(`Filter is verwijderd`);
            } else {
                toast.error("Oeps er ging even iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        } finally {
            //refreshGrid();
        }
    }, [])

    useEffect(() => {
        const innerUseEffect = async () => {
            try {
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/get-overview-filters/${overviewId}/`);
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(`[Overview] getOverviewData response was not ok: ${data.message}`);
                }
                setPresetFiltersForLookup(data.map(item => { return { ...item, type: item.userId == null ? 'Algemene filters' : 'Mijn filters' } }));

            } catch (e) {
                toast.error("Fout bij ophalen presetfilters");
            }
        };
        innerUseEffect();
    }, [overviewId]);

    const dataSourcePresetFiltersForLookup = new DataSource({
        store: {
            type: 'array',
            data: presetFiltersForLookup,
            key: 'id',
        },
        group: 'type',
    })

    const loadPresetFilter = useCallback(async (e) => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/get-overview-filters/${overviewId}/`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Overview] getOverviewData response was not ok: ${data.message}`);
            }
            return data;
        } catch (e) {

        }
    }, [overviewId])

    const updatePresetFilter = useCallback(async (id, data) => {
        console.log("updatePresetFilter", id, "data", data, "blaf");
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/update-overview-filter/${id}`, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(data)
            });
            if (res.ok) {
                toast.success(`Filter is bijgewerkt`);
            } else {
                toast.error("Oeps er ging even iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        } finally {
            //refreshGrid();
        }
    }, []);

    const insertPresetFilter = useCallback(async (data) => {
        console.log("insertPresetFilter", data);
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/create-overview-filter/${overviewId}`, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({ ...data, filter: JSON.stringify(presetFilterValue) })
            });
            if (res.ok) {
                toast.success(`Filter is toegevoegd`);
            } else {
                toast.error("Oeps er ging even iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        } finally {
            //refreshGrid();
        }
    }, [overviewId, presetFilterValue]);

    const dataSourcePresetFilter = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: loadPresetFilter,
            update: updatePresetFilter,
            remove: deletePresetFilter,
            insert: insertPresetFilter
        })
    }), [loadPresetFilter, deletePresetFilter, updatePresetFilter, insertPresetFilter]);


    return (
        <>
            <div>
                <Resizable
                    style={stylObj}
                    minHeight={200}
                    handles={"bottom"}
                >
                    <DataGrid
                        key={`key_${overviewId ?? controlRelationId}`}
                        title={dataGridRef?.current?.instance().state()}
                        className={""}
                        elementAttr={elementAttr}
                        ref={dataGridRef}
                        dataSource={dataSource}
                        cellHintEnabled={true}
                        showBorders={true}
                        hoverStateEnabled={true}
                        showRowLines={false}
                        showColumnLines={true}
                        id="overviewgrid"
                        rowAlternationEnabled={true}
                        allowColumnReordering={true}
                        noDataText="Geen resultaat"
                        columnAutoWidth={false}
                        columnResizingMode="widget"
                        allowColumnResizing={!isMobile}
                        height={"100%"}
                        onRowPrepared={onRowPrepared}
                        onCellClick={externalSelectFunction ?? onCellClick}
                        width={scrollHorizontal ? "auto" : "100%"}
                        repaintChangesOnly={true}
                        highlightChanges={true}
                        wordWrapEnabled={false}
                        filterSyncEnabled={true}
                        onOptionChanged={onOptionChanged}
                        onEditorValueChanged={onEditorValueChanged}
                    >
                        <RemoteOperations groupPaging={true} />
                        <Grouping autoExpandAll={true} contextMenuEnabled allowCollapsing={true} />
                        <GroupPanel visible={!isControlRelation && !isMobile} emptyPanelText="Sleep velden hier om te groeperen" />
                        <FilterPanel visible={!isMobile} />
                        {storeState && (
                            <StateStoring
                                enabled={storeState}
                                type="localStorage"
                                savingTimeout={1000}
                                storageKey={localStorageKey} />
                        )}
                        <Sorting mode="multiple" />
                        <Paging
                            defaultPageSize={50}
                        />
                        <Pager
                            visible={true}
                            allowedPageSizes={allowedPageSizes}
                            displayMode="adaptive"
                            showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true}
                            infoText={infoText}
                        />
                        <ColumnChooser enabled={true}>
                            <Position
                                my="right top"
                                at="right bottom"
                                of=".dx-datagrid-column-chooser-button"
                                offset="0 50"
                            />
                        </ColumnChooser>
                        <Toolbar>

                            <Item location="before">

                                <h3 className={`overviewtitle ${isMobile ? "mobile" : ""}`}>Ontwikkeling openstaand</h3>
                            </Item>
                            <Item location="center" name="groupPanel" />
                            <Item location="after">
                                <Lookup
                                    grouped={true}
                                    dataSource={dataSourcePresetFiltersForLookup}

                                    onSelectionChanged={(e) => {
                                        setPresetFilterId(e.selectedItem.id);
                                        setPresetFilterValue(e.selectedItem.filter == null ? null : JSON.parse(e.selectedItem.filter));
                                    }}
                                    cancelButtonText="Annuleren"
                                    showCancelButton={false}
                                    showClearButton={false}
                                    noDataText=""
                                    valueExpr="id"
                                    displayExpr="title"
                                    placeholder={/* cell.displayValue */ "Selecteer filter"}
                                    showDataBeforeSearch={true}

                                    cleanSearchOnOpening={true}

                                >
                                    <DropDownOptions

                                        showTitle={false}
                                        width={isMobile ? "80vw" : "600px"}
                                        resizeEnabled={!isMobile}


                                        animation={"pop"}

                                        dragEnabled={true}

                                    />
                                </Lookup>
                            </Item>
                            <Item location="after" locateInMenu={isMobile ? "always" : "auto"}>

                                <ButtonGroup
                                    selectionMode="none"
                                    focusStateEnabled={false}
                                    stylingMode={"outlined"}


                                    onItemClick={({ event, itemElement }) => {
                                        event.preventDefault();
                                    }}
                                >
                                    {allowUpdate && allowInlineEditing && (
                                        <ButtonGroupItem
                                            icon="fas fa-pencil"
                                            activeStateEnabled={inlineEditMode}
                                            type={inlineEditMode ? "success" : "default"}
                                            hint={`Bewerken staat ${inlineEditMode ? "aan" : "uit"}`}
                                            onClick={inlineEditModeClick}

                                        />)}
                                    {!isMobile && allowCreate && (
                                        <ButtonGroupItem
                                            icon="fas fa-plus-square"
                                            hint="Snel een nieuw record toevoegen"
                                            onClick={showPopupAddClick}
                                            type="default"
                                        />
                                    )}
                                    {allowCreate && (<ButtonGroupItem
                                        icon="fas fa-plus"
                                        hint="Voeg een nieuw record toe"
                                        onClick={goToAdd}
                                        type="default"
                                    />)}

                                    {!isControlRelation && !isMobile && (
                                        <ButtonGroupItem
                                            icon="fas fa-filter"

                                            type={storeState ? "success" : "default"}
                                            hint={`Filters opslaan staat ${storeState ? "aan" : "uit"}`}
                                            onClick={storeStateClick}
                                        />)}
                                    <ButtonGroupItem
                                        icon="fas fa-bookmark"
                                        onClick={onShowPopupSelectPresetFilterClick}
                                        hint="Toon opgeslagen filters"
                                        type="default"
                                    />
                                    <ButtonGroupItem
                                        icon="fas fa-eraser"
                                        // stylingMode="contained"
                                        type={hasFilter ? "success" : "default"}
                                        hint="Wis filters"
                                        onClick={clearFilterClick}

                                    />

                                    <ButtonGroupItem
                                        icon="fas fa-sync-alt"
                                        hint={`Vernieuwen data`}
                                        type="default"
                                        onClick={refreshClick}
                                    />

                                    {allowExportExcel && !isMobile && <ButtonGroupItem
                                        icon="fas fa-file-excel"
                                        type="default"
                                        hint="Download excel bestand"
                                        onClick={exportHandler}
                                    />}
                                    {allowExportExcel && !isMobile && <ButtonGroupItem
                                        icon="fas fa-file-pdf"
                                        type="default"
                                        hint="Download PDF bestand"
                                        onClick={exportPDFHandler}
                                    />}
                                </ButtonGroup>
                                {/* <DropDownButton
                                    text="Opties"
                                    icon="save"
                                    dropDownOptions={buttonDropDownOptions}
                                    items={data.downloads}
                                    // onItemClick={onItemClick}
                                /> */}
                            </Item>
                            <Item location="after" name="searchPanel" locateInMenu={"never"} />
                        </Toolbar>
                        <Editing
                            mode="cell"
                            allowUpdating={inlineEditMode}
                            allowAdding={true}
                            allowDeleting={!isMobile}
                            selectTextOnEditStart
                            refreshMode={autoRefresh === 1 ? "reshape" : "repaint"}

                            useIcons={true}
                        >
                            <Texts
                                confirmDeleteTitle="Item verwijderen"
                                confirmDeleteMessage="Weet u zeker dat u dit item wilt verwijderen?"
                            />
                        </Editing>
                        <KeyboardNavigation enabled={true} />

                        <FilterRow visible={!isMobile} />
                        <FilterBuilderPopup title="Stel je filter samen" />
                        <HeaderFilter visible={true} search={{ enabled: true }} allowSelectAll={true} />
                        <ColumnFixing enabled={true} />
                        <LoadPanel
                            enabled={true}
                            showPane={false}
                            text="Laden"
                        />
                        <SearchPanel
                            visible={true}
                            highlightCaseSensitive={false}
                            placeholder="Zoeken..."
                            width={isMobile ? "100" : "150"} />
                        <Scrolling
                            mode="standard"

                            showScrollbar="always"
                        />
                        {!isMobile && (
                            <MasterDetail
                                enabled={expandDetailViewId !== null}
                                component={renderMasterDetail}
                            />
                        )}
                        {manualSortColumnId && allowUpdate && (
                            <RowDragging
                                allowReordering={true}
                                onReorder={updateGridOrder}
                                showDragIcons={true}
                                autoScroll={true} />
                        )}
                        {renderedColumns}
                        {!isMobile && (
                            <Column type="buttons" width={"120"} caption={"Acties"} key='actionColumn'>
                                {!isMobile && <DataGridButton hint="Ga naar pagina" name="select" cssClass="primaryAccent" icon="fas fa-arrow-right" onClick={onRowSelect} />}
                                {allowUpdate && <DataGridButton hint="Bewerk dit record" name="update" cssClass="primaryAccent" icon="fas fa-pen-to-square" onClick={onEditClick} />}
                                {!isMobile && allowCreate && allowCopy && <DataGridButton hint="Dupliceer dit record" name="clone" cssClass="primaryAccent" icon="fas fa-clone" onClick={onCloneClick} />}
                                {allowDelete && <DataGridButton hint="Verwijder dit record" name="delete" icon="trash" cssClass="alert" />}
                            </Column>
                        )}

                        {renderedSummaries}
                    </DataGrid>
                </Resizable>
            </div >

            {showPopupAdd && (
                <QuickDetailviewAdd
                    targetId={detailViewIdAdd}
                    setOpenQuickAddModal={setOpenQuickAddModal}
                    openQuickAddModal={true}
                    refreshGrid={refreshGrid}
                    controlRelationId={controlRelationId}
                    relationTableItemId={tableItemId} // @
                    handleSaveAndCreateNew={handleSaveAndCreateNew}
                    handleSave={handleSave}
                    handleUserLeaveContinue={handleUserLeaveContinue}
                    handleClose={handleClose}
                    handleUserLeaveSave={handleUserLeaveSave}
                    handleAddNew={handleAddNew}
                />
            )
            }
            {
                duplicateData && (
                    <DuplicateDetailview
                        isRelation={isControlRelation}
                        duplicateData={duplicateData}
                        isOpen
                        handleSave={handleSaveEdit}
                        handleSaveAndCreateNew={handleSaveAndCreateNewEdit}
                        handleUserLeaveContinue={handleUserLeaveContinueEdit}
                        handleClose={handleCloseEdit}
                        handleUserLeaveSave={handleUserLeaveSaveEdit}
                        handleDelete={handleDeleteEdit}
                        handleAddNew={handleAddNew}
                    />
                )
            }
            {
                popupEditRecordId != null && popupEditRecordId !== false && (
                    <QuickEdit
                        targetId={popupEditRecordId} // this is the tableitemId
                        isRelation={false}
                        setIsEditing={setPopupEditRecordId}
                        isEditing={true}
                        currentOverview={/*currentOverview*/ { preferredDetailViewId: preferredDetailViewId }}
                        refreshGrid={refreshGrid}
                        controlRelationId={controlRelationId}
                        overrideDetailViewId={preferredDetailViewIdEdit}
                        handleSave={handleSaveEdit}
                        handleSaveAndCreateNew={handleSaveAndCreateNewEdit}
                        handleUserLeaveContinue={handleUserLeaveContinueEdit}
                        handleClose={handleCloseEdit}
                        handleUserLeaveSave={handleUserLeaveSaveEdit}
                        handleDelete={handleDeleteEdit}
                        handleAddNew={handleAddNew}
                    />
                )
            }
            {
                selectedHtmlTableItemId != null && (
                    <EditHtmlField
                        rowIndex={selectedHtmlTableItemId.rowIndex}
                        columnIndex={selectedHtmlTableItemId.columnIndex}
                        alias={selectedHtmlTableItemId.alias}
                        tableId={tableId}
                        tableItemId={selectedHtmlTableItemId.tableItemId}
                        columnId={selectedHtmlTableItemId.columnId}
                        readOnly={selectedHtmlTableItemId.isHtmlViewer || !inlineEditMode}
                        setSelectedHtmlTableItemId={setSelectedHtmlTableItemId}
                        isHtmlViewer={selectedHtmlTableItemId.isHtmlViewer}
                        isHtmlEditor={selectedHtmlTableItemId.isHtmlEditor}
                        isHtmlEditorAdvanced={selectedHtmlTableItemId.isHtmlEditorAdvanced}
                        refreshFunction={selectedHtmlTableItemId.refreshFunction}
                        dataGridRef={selectedHtmlTableItemId.dataGridRef}
                    />
                )
            }
            {
                showPopupAddPresetFilter && <Popup
                    className="bl-popup"
                    title="Filter opslaan"
                    animation={false}
                    showTitle={true}
                    resizeEnabled={true}
                    visible={true}
                    showCloseButton={true}
                    onHiding={() => { setShowPopupAddPresetFilter(false); }}
                    toolbarItems={
                        [
                            {
                                toolbar: "bottom",
                                widget: "dxButton",
                                location: "center",
                                options:
                                {
                                    text: 'Opslaan',
                                    onClick: async (e) => {
                                        const grid = dataGridRef?.current?.instance();
                                        try {
                                            const res = await msalFetch(null,
                                                `${appSettings.api.endpoint}/api/overview/create-overview-filter/${overviewId}`,
                                                {
                                                    method: "POST",
                                                    headers: { "Content-type": "application/json" },
                                                    //body: JSON.stringify({ ...formAddFilter, filter: JSON.stringify(grid.getCombinedFilter()) })
                                                    body: JSON.stringify({ ...formAddFilter, filter: JSON.stringify(presetFilterValue) })
                                                }
                                            );
                                            if (res.ok) {
                                                setFormAddFilter(defaultFormAddFilter);
                                                toast.success(`Filter '${formAddFilter.title}' is toegevoegd`);
                                            } else {
                                                toast.error("Oeps er ging even iets mis...");
                                            }
                                        } catch (error) {
                                            toast.error("Oeps er ging even iets mis...");
                                            throw error;
                                        } finally {
                                            //refreshGrid();
                                        }
                                    }
                                }
                            }

                        ]
                    }
                >
                    <TextBox
                        value={formAddFilter.title}
                        valueChangeEvent="input"
                        placeholder="Titel"
                        onValueChanged={(e) => { setFormAddFilter(old => { return { ...old, title: e.value } }) }}

                    />
                    <TextArea
                        value={formAddFilter.description}
                        valueChangeEvent="input"
                        placeholder="Omschrijving"
                        onValueChanged={(e) => { setFormAddFilter(old => { return { ...old, description: e.value } }) }}

                    />
                    <CheckBox
                        value={formAddFilter.limitedToUser}
                        onValueChanged={(e) => { setFormAddFilter(old => { return { ...old, limitedToUser: e.value } }) }}
                    />
                </Popup>
            }
            {showPopupSelectPresetFilter && <Popup
                className="bl-popup"
                title="Filter selecteren"
                animation={false}
                showTitle={true}
                resizeEnabled={true}
                visible={true}
                showCloseButton={true}
                onHiding={() => { setShowPopupSelectPresetFilter(false); }}
                toolbarItems={
                    [

                    ]
                }
            >
                <DataGrid
                    dataSource={dataSourcePresetFilter}
                    showBorders={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    showColumnLines={false}
                    wordWrapEnabled={false}
                    width={"auto"}
                    loadPanel={true}
                    className="bl-news"
                    onRowClick={onPresetFilterRowClick}
                    onInitNewRow={onPresetFilterInitNewRow}
                >
                    <RowDragging
                        allowReordering={true}
                        onReorder={updatePresetFilterOrder}
                        showDragIcons={true}
                        autoScroll={true} />
                    <Editing
                        allowAdding={true}
                        allowDeleting={true}
                        allowUpdating={true}
                        mode="row"
                        useIcons={true}
                    />
                    <Grouping autoExpandAll={true} contextMenuEnabled allowCollapsing={true} />
                    <Sorting mode={"none"} />
                    <Column
                        caption="Titel"
                        dataField="title"
                        dataType="string"
                    />
                    <Column
                        key="c_limitedToUserNonAdmin"
                        dataField="limitedToUser"
                        caption="Type"
                        dataType="boolean"
                        mandatory={true}
                        groupIndex={0}
                        defaultValue={true}
                        readOnly={true}
                        trueText="Mijn filters"
                        falseText="Algemene filters"
                    />
                    {currentUser?.admin && <Column
                        key="c_limitedToUserAdmin"
                        dataField="limitedToUser"
                        caption="Zichtbaar"
                        dataType="boolean"
                        mandatory={true}
                        defaultValue={true}


                        trueText="Voor mezelf"
                        falseText="Voor iedereen"
                    />}
                    {currentUser?.admin && <Column
                        caption="Filter"
                        dataField="filter"
                        dataType="string"
                        allowEditing={false}
                    />}
                    <Column type="buttons" width={"120"} caption={"Acties"} key='filterPresetActionColumn'>
                        <DataGridButton hint="Ga naar filter" name="select" cssClass="primaryAccent" icon="fas fa-arrow-right" onClick={onPresetFilterSelectionChanged} />
                        <DataGridButton hint="Update filter met huidige selectie" name="select" cssClass="primaryAccent" icon="fas fa-arrow-up" onClick={onPresetFilterUdateFilter} visible={(e) => currentUser.isAdmin || e.row.data.userId === currentUser.id} />
                        {allowUpdate && <DataGridButton hint="Bewerk titel" name="edit" cssClass="primaryAccent" icon="fas fa-pen-to-square" visible={(e) => currentUser.isAdmin || e.row.data.userId === currentUser.id} />}
                        {allowDelete && <DataGridButton hint="Verwijder filter" name="delete" icon="trash" cssClass="alert" visible={(e) => currentUser.isAdmin || e.row.data.userId === currentUser.id} />}
                    </Column>
                </DataGrid>
            </Popup>}
        </>
    );
};

export default List;


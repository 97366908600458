import { useMsal } from "@azure/msal-react";
import React, { useCallback, useState, useEffect, useRef } from "react";
import ModalControls from "../parts/modalControls/ModalControls";
import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings";
import "./configModalStyles.scss";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { SelectBox, TextBox } from "devextreme-react";

import FieldSet from "./setTypes/FieldSet";
import RelatedSet from "./setTypes/RelatedSet";
import toast from "react-hot-toast";
import TabSet from "./setTypes/TabSet";
import { useModalContext } from "../../../context/modalContext";
import UserLeave from "../../notifications/userLeaveNotifications/UserLeave";

const EditSet = ({
    isEditing,
    setIsEditing,
    isEdit,
    isCreate,
    data,
    columns,
    tabRelations,
    datasetId,
    refreshFunction,
}) => {
    const msal = useMsal();
    const {
        setNotificationData,
        setFormInValidNotification,
        saveChangesNotification,
        setSaveChangesNotification,
    } = useModalContext();
    const [changeInForm, setChangeInForm] = useState(false);
    const [throwNotification, setThrowNotification] = useState(false);
    const [formValue, setFormValue] = useState(data);
    const [setType, setSetType] = useState(
        data && data.fieldType ? data.fieldType : ""
    );

    const [loading, setLoading] = useState(true);

    const [setColumns, setSetColumns] = useState(
        !isCreate && data && data.columns
    );

    useEffect(() => {
        if (isCreate) {
            setFormValue({
                columns: [],
                name: "",
                newButton: true,
                type: 2,
            });
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!isCreate) {
            let tempForm = data;
            let sortedColumns = [];
            data.columns.map((item, index) => {
                let tempItem = item;
                tempItem["id"] = index + 1;
                sortedColumns.push(tempItem);
            });
            // let primairies = data.columns.filter(t=>t.width ===0);

            // let notPrimairy

            sortedColumns.sort((a, b) => {
                return a.sort - b.sort;
            });

            tempForm.columns = sortedColumns;
            setFormValue(tempForm);
        }
    }, [isEditing]);

    const setgridRef = useRef();

    const setTypes = ["Veldenset", "Afbeeldingen", "Gerelateerd", "Tabbladen"];

    //[{v:'controlPropertyId', d: 'Veldenset'}, {v:'controlImageId', d:'Afbeelding'}, {v:'controlRelationId',d:'Gerelateerd'}, {v:'controlTabId', d:'Tabbladen'}]

    const checkChanges = () => {
        if (!changeInForm) {
            setChangeInForm(true);
        }
    };

    const checkMandatoryField = (value, target) => {
        if (value.trim() !== "") {
            target.remove("bl-mandatory-field");
        } else {
            target.add("bl-mandatory-field");
        }
    };

    const deleteColumn = (column) => {
        let tempForm = formValue;

        let tempColumns = [];
        let sortedColumns = [];

        let target = column.key.sort;
        formValue.columns.map((item) => {
            if (item.sort !== target) {
                tempColumns.push(item);
            }
            return tempColumns;
        });

        tempForm["columns"] = tempColumns;

        setFormValue(tempForm);

        setSetColumns(tempForm.columns);
        checkChanges();
    };

    const addColumn = () => {
        let tempForm = formValue;
        tempForm.columns.push({
            controlPropertyId: formValue.id,
            columnId: "",
            sort: formValue.columns.length,
            width: 6,
        });

        setFormValue(tempForm);
        setSetColumns(tempForm.columns);
        checkChanges();

        setgridRef.current.instance().refresh(false);
    };

    const handleReorder = (e) => {
        // Related has 2 ID fields which are not visible, so they dont show up in the ordering,
        // but they need to be present as the first and last
        const openingHiddenField = formValue.columns.find((t) => t.width === 0);
        const endingHiddenField = formValue.columns.findLast((t) => t.width === 0);
        const hasHiddenFields = Boolean(openingHiddenField && endingHiddenField);
        const columns = formValue.columns.filter((t) => t.width !== 0).sort((a, b) => {
            return a.sort - b.sort;
        });

        columns.splice(e.fromIndex, 1);
        columns.splice(e.toIndex, 0, e.itemData);

        const sortedColumns = columns.map((item, index) => {
            item.sort = hasHiddenFields ? index + 1 : index;
            return item;
        });
        formValue.columns = [openingHiddenField, ...sortedColumns, endingHiddenField].filter(Boolean);
        setFormValue(formValue);
        checkChanges();
        setgridRef.current.instance().refresh(true);
    };

    const succesSave = () => {
        toast.success("Gegevens succesvol opgeslagen...");
        setChangeInForm(false);
        setIsEditing(false);
        setChangeInForm(false);
    };

    const handleSaveClick = async () => {
        console.log("deze functie");
        const dirtyDocument = document
            .getElementsByClassName("bl-set-modal")[0]
            .getElementsByClassName("bl-mandatory-field");

        let tempForm = formValue;

        if (dirtyDocument.length > 0) {
            setNotificationData(dirtyDocument);
            setFormInValidNotification(true);

            setThrowNotification(false);
            return;
        } else if (isCreate) {
            let tempForm = formValue;

            let postUrl;
            const veldType = tempForm.fieldType;
            let type;
            let fieldType = "";

            if (veldType === "Veldenset") {
                type = 2;
                fieldType = "property";
            } else if (veldType === "Tabbladen") {
                type = 4;
                fieldType = "tab";
            } else if (veldType === "Gerelateerd") {
                type = 1;
                fieldType = "relation";
            }
            if (veldType === "Afbeeldingen") {
                type = 3;
                fieldType = "image";
            }

            postUrl = `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId}/control/${fieldType}`;
            try {
                const toastId = toast.loading("Set wordt opgeslagen");
                const res = await msalFetch(msal, postUrl, {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(formValue),
                });

                if (res.ok) {
                    toast.success("Set is opgeslagen", { id: toastId });
                    setChangeInForm(false);
                    setIsEditing(false);
                    setChangeInForm(false);
                    refreshFunction();
                } else {
                    toast.error("Set kon niet worden opgeslagen");
                }
            } catch (error) {

            }
        } else {
            let postUrl;
            let tempFormValue = formValue;

            if (formValue.relationTableId && !formValue.controlTabId) {
                tempFormValue["type"] = 1;
                postUrl = `${appSettings.api.endpoint}/api/configurator/dataset/${tempFormValue.tableId}/control/relation/${tempFormValue.id}`;
            }
            if (formValue.fieldType === "Tabbladen") {
                tempFormValue["type"] = 4;
                postUrl = `${appSettings.api.endpoint}/api/configurator/dataset/${tempFormValue.tableId}/control/tab/${tempFormValue.id}`;
            }
            if (formValue.fieldType === "Veldenset") {
                tempFormValue["type"] = 2;
                postUrl = `${appSettings.api.endpoint}/api/configurator/dataset/${tempFormValue.tableId}/control/property/${tempFormValue.id}`;
            }
            if (formValue.fieldType === "Afbeeldingen") {
                tempFormValue["type"] = 3;
                postUrl = `${appSettings.api.endpoint}/api/configurator/dataset/${tempFormValue.tableId}/control/image/${tempFormValue.id}`;
            }
            try {
                const res = await msalFetch(msal, postUrl, {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(tempFormValue),
                });

                if (res.ok) {
                    toast.success("Gegevens succesvol opgeslagen...");
                    setChangeInForm(false);
                    setIsEditing(false);
                    setChangeInForm(false);
                    refreshFunction();
                } else {
                    toast.error("Oeps er ging iets mis...");
                }
            } catch (error) { }
        }
    };
    const handleExitClick = () => {
        if (changeInForm) {
            setThrowNotification(true);
        } else {
            setIsEditing(false);
        }
    };

    const addButton = () => {
        return (
            <div
                className="bl-btn bl-add-btn bl-config-header-btn"
                onClick={addColumn}
            >
                <i className="fas fa-plus" />
            </div>
        );
    };
    const deleteButton = (column) => {
        return (
            <div className="bl-column-controls">
                <i
                    className="fas fa-trash bl-column-control-btn bl-column-delete-btn"
                    onClick={() => deleteColumn(column)}
                />
            </div>
        );
    };

    const handleDiscardAndContinue = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);

    const handleSaveAndContinue = () => {
        handleSaveClick();
    };

    return (
        <main className={`bl-modal ${isEditing ? "show-modal" : ""} bl-set-modal`}>
            <article className="bl-modal-content">
                <header className="bl-modal-header">
                    <h3>{isCreate ? "Toevoegen set" : "Wijzigen set"}</h3>
                    <div className="bl-exit-modal" onClick={handleExitClick}>
                        <i className="fas fa-times" />
                    </div>
                </header>

                <div className="bl-modal-inner">
                    <ModalControls
                        handleSaveClick={handleSaveClick}
                        handleExitClick={handleExitClick}
                        hasExitFunction={true}
                        modalTitle={isCreate ? "Set type kiezen en aanmaken" : "Wijzigen set"}
                        changeInForm={changeInForm}
                        setThrowNotification={setThrowNotification}
                        throwNotification={throwNotification}
                        hasPreview={true}
                        previewObject={formValue}
                    />{" "}
                    {!loading && (
                        <div className="bl-scroll-box active-scroll-box">
                            <Form.Group>
                                <Row>
                                    <Col lg="3">
                                        <Form.Label>Naam</Form.Label>
                                    </Col>
                                    <Col lg="3">
                                        <TextBox
                                            placeholder="Naam..."
                                            className={isCreate && "bl-mandatory-field"}
                                            defaultValue={formValue.name && formValue.name}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(e) => {
                                                let tempForm = formValue;
                                                tempForm["name"] = e.value;
                                                checkChanges();
                                                setFormValue(tempForm);
                                                checkMandatoryField(e.value, e.element.classList);
                                            }}
                                        />
                                    </Col>
                                    <Col lg="3">
                                        <Form.Label>Type</Form.Label>
                                    </Col>
                                    <Col lg="3">
                                        <SelectBox
                                            searchEnabled
                                            items={setTypes}
                                            defaultValue={formValue.fieldType && formValue.fieldType}
                                            className={isCreate && "bl-mandatory-field"}
                                            onValueChanged={(e) => {
                                                let tempForm = formValue;

                                                tempForm["fieldType"] = e.value;

                                                checkChanges();
                                                checkMandatoryField(e.value, e.element.classList);
                                                setFormValue(tempForm);
                                                setSetType(e.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>

                                </Row>
                            </Form.Group>
                            <div key={setType}>
                                {setType === "Veldenset" && (
                                    <FieldSet
                                        columns={columns}
                                        formValue={formValue}
                                        setFormValue={setFormValue}
                                        setColumns={setColumns}
                                        setSetColumns={setSetColumns}
                                        setgridRef={setgridRef}
                                        checkChanges={checkChanges}
                                        checkMandatoryField={checkMandatoryField}
                                        handleReorder={handleReorder}
                                        deleteButton={deleteButton}
                                        addButton={addButton}
                                        isCreate={isCreate}
                                    />
                                )}
                                {setType === "Afbeeldingen" && (
                                    <FieldSet
                                        columns={columns}
                                        formValue={formValue}
                                        setFormValue={setFormValue}
                                        setColumns={setColumns}
                                        setSetColumns={setSetColumns}
                                        setgridRef={setgridRef}
                                        checkChanges={checkChanges}
                                        checkMandatoryField={checkMandatoryField}
                                        handleReorder={handleReorder}
                                        deleteButton={deleteButton}
                                        addButton={addButton}
                                        isCreate={isCreate}
                                    />
                                )}

                                {setType === "Gerelateerd" && (
                                    <RelatedSet
                                        formValue={formValue}
                                        setFormValue={setFormValue}
                                        setColumns={setColumns}
                                        setSetColumns={setSetColumns}
                                        setgridRef={setgridRef}
                                        checkChanges={checkChanges}
                                        checkMandatoryField={checkMandatoryField}
                                        handleReorder={handleReorder}
                                        deleteButton={deleteButton}
                                        addButton={addButton}
                                        setChangeInForm={setChangeInForm}
                                        isCreate={isCreate}
                                        datasetId={datasetId}
                                    />
                                )}

                                {setType === "Tabbladen" && (
                                    <TabSet
                                        formValue={formValue}
                                        setFormValue={setFormValue}
                                        setColumns={setColumns}
                                        setSetColumns={setSetColumns}
                                        setgridRef={setgridRef}
                                        checkChanges={checkChanges}
                                        checkMandatoryField={checkMandatoryField}
                                        handleReorder={handleReorder}
                                        deleteButton={deleteButton}
                                        addButton={addButton}
                                        tabRelations={tabRelations}
                                        setChangeInForm={setChangeInForm}
                                        isCreate={isCreate}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </article>

            {throwNotification && (
                <UserLeave
                    handleCancel={() => setThrowNotification(false)}
                    handleSaveAndContinue={handleSaveAndContinue}
                    handleDiscardAndContinue={handleDiscardAndContinue}
                />
            )}
        </main>
    );
};

export default EditSet;

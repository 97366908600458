import React, { useEffect } from "react";

import "./modalControls.scss";

import { useModalContext } from "../../../../context/modalContext";

const ModalControls = ({
    handleSaveClick,
    modalTitle,
    changeInForm,
    hasExitFunction,
    handleExitClick,
    saveAndBlank,
    hasGoTo,
    goToUrl,
    hasNoHeaderExit,
}) => {
    const { setIsEditing, setModal } = useModalContext();

    useEffect(() => {
        const downHandler = (e) => {
            if (e.key === "Escape" && hasExitFunction) {
                handleExitClick();
            } else {
                return;
            }
        };
        window.addEventListener("keydown", downHandler);

        // Remove event listeners on cleanup

        return () => {
            window.removeEventListener("keydown", downHandler);
        };
    }, [hasExitFunction, handleExitClick]);

    return (
        <header className="bl-modal-control">
            <div className="bl-modal-controls">


                <div
                    className={`bl-btn bl-save-btn ${changeInForm ? "" : "bl-disabled-btn"
                        }`}
                    onClick={() => {

                        handleSaveClick(false);
                    }}
                    title="Wijzigingen opslaan"
                >
                    <i className="fa-solid fa-floppy-disk-circle-xmark" />
                </div>

                {saveAndBlank && (
                    <div
                        className={`bl-btn bl-save-and-add-btn ${changeInForm ? "" : "bl-disabled-btn"
                            }`}
                        onClick={() => handleSaveClick(true)}
                        title="Opslaan en nieuw"
                    >
                        <i className="fa-solid fa-floppy-disk-circle-arrow-right fa-2x" />
                    </div>
                )}
                {/* 
                {!hasNoHeaderExit && (
                    <div
                        className="bl-btn bl-close-btn "
                        onClick={() => {
                            if (hasExitFunction) {
                                handleExitClick();

                            } else {
                                setIsEditing(false);
                                setModal("");
                            }
                        }}
                        title="Sluiten"
                    >
                        <i className="fa-solid fa-times fa-6x" />
                    </div>
                )} */}
            </div>
        </header>
    );
};

export default ModalControls;

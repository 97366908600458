import Draggable from 'devextreme-react/draggable';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useMemo, useState, useCallback } from "react";
import { TextBox } from 'devextreme-react/text-box';
import "./Scheduler.scss";
import toast from "react-hot-toast";
import Button from 'devextreme-react/button';
import QuickDetailviewAdd from "../modals/AddDetailview/quickDetailviewAdd/QuickDetailviewAdd.js";

const Items = ({
    group,
    draggingGroupName = 'default',
    items = [],
    draggableDetailViewId,
    setDraggableDetailViewItemId,
}) => items.length === 0 ? <div /> : ( // ScrollView crashes if not returning a div
    <div className='bl-draggable-container' >
        {group && <h4 className='groupheader'>{group}</h4>}
        <Draggable
            id="list"
            group={draggingGroupName}
            className='bl-draggable'
            dragDirection="vertical"
        >
            {items.map(item => (
                <>
                    <Draggable
                        key={item.value}
                        group={draggingGroupName}
                        className="item bl-draggable-item"
                        clone={true}
                        data={item}
                        onDragStart={(e) => { console.log("onDragStart", e); }}
                        onDragEnd={(e) => { console.log("onDragEnd", e); }}
                    >
                        <i
                            className="fa-solid fa-square coloricon"
                            style={{ color: item.color }} // Use 'color' or another relevant CSS property
                        />
                        {draggableDetailViewId && (
                            <button
                                style={{ all: 'unset' }}
                                type="button"
                                className="location"
                                title={`${item.infoLabel ?? item.label}`}
                                onClick={() => { setDraggableDetailViewItemId(item.primairyKeyValue) }}
                            >
                                <span className="powerreporticon fa-solid fa-arrow-up-right-from-square" tabIndex="0" role="button" />
                            </button>
                        )}
                        {item.label}
                    </Draggable>
                </>
            ))}
        </Draggable>
    </div>
);

const SchedulerDraggableItems = ({
    draggingGroupName,
    draggableItems,
    title,
    getDraggableItems,
    draggableDetailViewId,
    setDraggableDetailViewItemId,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isExecuting, setIsExecuting] = useState(false);
    const [showAddDraggableDetailViewCreate, setShowAddDraggableDetailViewCreate] = useState();

    const filteredItems = useMemo(() => draggableItems.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase()),
    ), [draggableItems, searchQuery]);

    const updateDraggableItems = useCallback(() => {
        setIsExecuting(true);
        getDraggableItems();

        toast.success("Lijst is vernieuwd");
        setIsExecuting(false);
    }, [getDraggableItems]);

    const groups = useMemo(() => [
        ...new Set(draggableItems
            .filter(item => item.groupHeaderName != null)
            .map(item => item.groupHeaderName)
        )
    ], [draggableItems]);

    return (
        <div className="schedulercontent">
            < div className="scheduleroptions" >
                <TextBox
                    placeholder={`Zoeken binnen ${title.toLowerCase()}`}
                    value={searchQuery}
                    className='bl-draggable-search'
                    onValueChanged={(e) => setSearchQuery(e.value)}
                    valueChangeEvent="keyup"
                    showClearButton={true}

                ><i className="dx-icon dx-icon-search" /></TextBox>
                <Button
                    stylingMode="outlined"
                    className="schedulerbutton"
                    type="default"
                    hint={"Ververs deze lijst"}
                    disabled={isExecuting}
                    icon={`fas ${isExecuting ? 'fa-pulse fa-spinner' : "fa-refresh"}`}
                    onClick={updateDraggableItems}
                />
                <Button
                    stylingMode="outlined"
                    className="schedulerbutton"
                    type="default"
                    hint={"Maak nieuw item"}
                    disabled={isExecuting}
                    icon={`fas ${isExecuting ? 'fa-pulse fa-spinner' : "fa-plus"}`}
                    onClick={() => { setShowAddDraggableDetailViewCreate(true); }}
                />
            </div >

            {
                filteredItems.length === 0 && (
                    <p>Geen {title.toLowerCase()} met <em>"{searchQuery}"</em> gevonden.</p>
                )
            }

            {
                filteredItems.length > 0 && (

                    <ScrollView id="scroll"  >
                        {groups.length === 0 && (
                            <Items
                                group={null}
                                draggingGroupName={draggingGroupName}
                                draggableDetailViewId={draggableDetailViewId}
                                setDraggableDetailViewItemId={setDraggableDetailViewItemId}
                                items={filteredItems}
                            />
                        )}
                        {groups.length > 0 && groups.map((group) => (
                            <Items
                                key={group}
                                group={group}
                                draggingGroupName={draggingGroupName}
                                draggableDetailViewId={draggableDetailViewId}
                                setDraggableDetailViewItemId={setDraggableDetailViewItemId}
                                items={filteredItems.filter(({ groupHeaderName }) => groupHeaderName === group)}
                            />
                        ))}
                    </ScrollView>

                )
            }
            {draggableDetailViewId && showAddDraggableDetailViewCreate && <QuickDetailviewAdd
                setOpenQuickAddModal={setShowAddDraggableDetailViewCreate}
                //openQuickAddModal={insertRecord}
                refreshGrid={() => { console.log("MOETNOG - refreshGrid") }}
                controlRelationId={null}
                targetId={draggableDetailViewId}
                handleSaveAndCreateNew={() => { console.log("handleSaveAndCreateNew"); }}
                handleSave={() => { console.log("handleSave"); }}
                handleUserLeaveContinue={() => { console.log("handleUserLeaveContinue"); }}
                handleClose={() => { setShowAddDraggableDetailViewCreate(false); updateDraggableItems(); }}
                handleUserLeaveSave={() => { setShowAddDraggableDetailViewCreate(false); updateDraggableItems(); }}

            />}
        </div>
    );
}

export default SchedulerDraggableItems;

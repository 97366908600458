import { useMsal } from "@azure/msal-react";
import { TextArea } from "devextreme-react";
import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import { useModalContext } from "../../../context/modalContext";
import ModalControls from "../parts/modalControls/ModalControls";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react";
import TextboxField from "./veldTypes/TextboxField";
import Loading from "../../loading/Loading";
import toast, { Toaster } from "react-hot-toast";
import OnOffField from "./veldTypes/OnOffField";
import ActionButtonField from "./veldTypes/ActionButtonField";
import DependendOnOtherColumn from "./veldTypes/DependendOnOtherColumn";
import Autonummering from "./veldTypes/Autonummering";
import Berekend from "./veldTypes/Berekend";
import StandaardVeld from "./veldTypes/StandaardVeld";
import Datum from "./veldTypes/Datum";
import "./editVeld.scss";
import DatumTijd from "./veldTypes/DatumTijd";
import Decimal from "./veldTypes/Decimal";
import Email from "./veldTypes/Email";
import Euro from "./veldTypes/Euro";
import Gebruiker from "./veldTypes/Gebruiker";
import Keuzelijst from "./veldTypes/Keuzelijst";
import Kleur from "./veldTypes/Kleur";
import Memo from "./veldTypes/Memo";
import Numeriek from "./veldTypes/Numeriek";
import Samengesteld from "./veldTypes/Samengesteld";
import DependendOnSingleColumn from "./veldTypes/DependendOnSingleColumn";
import ZoekbareKeuzelijst from "./veldTypes/ZoekbareKeuzelijst";
import UserLeave from "../../notifications/userLeaveNotifications/UserLeave";
import AVAK from "./veldTypes/AVAK";
import FTP from "./veldTypes/FTP";
import Percentage from "./veldTypes/Percentage";
import Selectielijst from "./veldTypes/Selectielijst";
import { CheckBox } from "devextreme-react";
import { ColumnType } from "../../../enums/columnType";
import { ColumnDisplayValueMemo } from "../../../enums/columnDisplayValue.js";
import { Popup } from 'devextreme-react/popup';
import { ColumnDisplayValueDec } from "../../../enums/columnDisplayValue";
import { ColumnValidationDec, ColumnValidationEmail } from "../../../enums/columnValidation";
import { SystemTable } from "../../../enums/systemTable.js";

const EditVeld = ({
    editData,
    setIsEditing,
    isEditing,
    datasetId,
    setData,
    currentDataset,
    getDatasetColumn,
}) => {
    console.log("[EditVeld] - editData", editData);
    const msal = useMsal();
    const { setFormInValidNotification, setNotificationData } = useModalContext();
    const [loading, setLoading] = useState(true);
    const [fieldValue, setFieldValue] = useState(editData);
    const [columnTypes, setColumnTypes] = useState();
    const [throwNotification, setThrowNotification] = useState(false);
    const [conditionalField, setConditionalField] = useState(editData.columnType ? editData.columnType.name : "Tekst");
    const [changeInForm, setChangeInForm] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [isCreate, setIsCreate] = useState(editData.isCreate && editData.isCreate);
    const [fieldName, setFieldName] = useState(fieldValue.name && fieldValue.name);
    const [isSystemColumn, setIisSystemColumn] = useState(fieldValue.isSystemColumn && fieldValue.isSystemColumn);
    const [fieldId, setFieldId] = useState(fieldValue.id && fieldValue.id);
    const [userTableId, setUserTableId] = useState(null);

    //console.log("fieldvalue", fieldValue);
    //console.log("datasetId", datasetId);
    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    useEffect(() => {
        if (isEditing) {
            if (!isCreate) {
                setFieldValue(editData);
            } else if (isCreate) {
            }
            // setRerender(!rerender);
        }
    }, [isEditing, editData, isCreate]);

    const handleSaveClick = async (newBlank) => {
        let allDocument = document.getElementsByClassName("bl-mandatory-field");
        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);
            setThrowNotification(false);

            return null;
        }
        if (!isCreate) {
            try {
                const res = await msalFetch(msal,
                    `${appSettings.api.endpoint}/api/configurator/dataset/${fieldValue.tableId}/column/${fieldValue.id}`,
                    {
                        method: "PUT",
                        headers: { "Content-type": "application/json" },
                        body: JSON.stringify(fieldValue),
                    }
                );
                // const data = await res.json();

                if (res.ok) {
                    setChangeInForm(false);
                    setIsEditing(false);
                    getDatasetColumn();
                    toast.success("De gegevens zijn successvol opgeslagen...");
                } else {
                    const reply = await res.json();
                    toast.error(`${reply.exceptionMessage}`);
                }
                return;
            } catch (error) {
                toast.error("Oeps er is iets mis gegaan...");

                throw error;
            }
        } else {
            try {
                const res = await msalFetch(msal,
                    `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId.id}/column/`,

                    {
                        method: "POST",
                        headers: { "Content-type": "application/json" },
                        body: JSON.stringify(fieldValue),
                    }
                );
                // const data = await res.json();

                if (res.ok) {
                    toast.success("De gegevens zijn successvol opgeslagen...");
                    if (newBlank) {
                        setChangeInForm(false);
                        setFieldValue({ columnType: columnTypes.find((t) => t.id === 1) });
                        setConditionalField("Tekst");
                        getColumnTypes();
                        setFieldName("");
                    } else {
                        setChangeInForm(false);
                        setIsEditing(false);
                        getDatasetColumn();
                    }
                } else {
                    const reply = await res.json();
                    toast.error(`${reply.exceptionMessage}`);
                }
                return;
            } catch (error) {
                toast.error("Oeps er is iets mis gegaan...");

                throw error;
            }
        }
    };
    const conditionalFieldCheck = (name) => {
        setConditionalField(name);
    };

    const getColumnTypes = useCallback(async () => {
        setLoading(true);
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/column/type`);
            const data = await response.json();

            // Type 'Geen' get's taken out
            let newColumnTypes = data.filter((t) => t.name !== "Geen");
            setColumnTypes(newColumnTypes);
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    }, []);

    const handleTypeChange = (e) => {
        let tempFieldValue = fieldValue;
        columnTypes.find(col => col.id === e);
        tempFieldValue.extraField1 = null;
        tempFieldValue.showImage = false;
        tempFieldValue.inputTableQueryWhere = null;
        switch (e) {
            case ColumnType.MEMO: {
                tempFieldValue["columnDisplayValue"] = columnTypes.find(ct => ct.id === ColumnType.MEMO).columnDisplayValues.find(cdv => cdv.id === ColumnDisplayValueMemo.Text);
                break;
            }
            case ColumnType.USRS: {
                tempFieldValue.inputTableQueryWhere = "Active = 1";
                break;
            }
            case ColumnType.DEC: {
                tempFieldValue.columnDisplayValue = ColumnDisplayValueDec.Precision2
                tempFieldValue.columnValidation = ColumnValidationDec.Precision2;
                break;
            }
            case ColumnType.EMAIL: {

                break;
            }
            case ColumnType.SELECT: {
                console.log("tempFieldValue.dependent = false;")
                tempFieldValue.dependent = false;
                break;
            }
            default:
                break;
        }

        tempFieldValue.columnType = columnTypes.filter((type) => type.id === e);
        tempFieldValue.columnType = tempFieldValue.columnType[0];
        setFieldValue(tempFieldValue);
        conditionalFieldCheck(tempFieldValue.columnType.name);

        let allDocument = document.getElementsByClassName("bl-mandatory-field");
        if (allDocument.length === 0) {
            checkChanges();
        }
    };

    const getUserTableId = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/table/get-system-table/${SystemTable.bl_users}`);
            const data = await response.json();
            setUserTableId(data.id);
        } catch (error) {
            setLoading(false);
            throw "Get User table Id error";
        }
    }, []);

    useEffect(() => {
        getColumnTypes();
        getUserTableId();
    }, [getColumnTypes, getUserTableId]);

    const handleExitClick = () => {
        if (changeInForm) {
            setThrowNotification(true);
        } else {
            setIsEditing(false);
        }
    };

    const handleDiscardAndContinue = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);

    const handleSaveAndContinue = () => {
        handleSaveClick();
    };

    return (
        <Popup

            title={isCreate ? "Toevoegen veld" : "Wijzigen veld"}
            className="bl-popup"
            showTitle={true}
            resizeEnabled={true}
            dragEnabled={true}
            animation={false}
            visible={true}
            showCloseButton={true}
            onHiding={(e) => {
                // TODO: throwNotification, but it's handled by the child Content.. :(
                // handleClose && handleClose();
                setIsEditing(false);
            }}
            toolbarItems={[{
                toolbar: "top",
                widget: "dxButton",
                location: "after",
                // className: "Button",
                options:
                {
                    icon: "fa-solid fa-floppy-disk-circle-xmark",

                    stylingMode: 'text',
                    type: 'default',
                    hint: 'Opslaan',
                    onClick: (e) => {

                        handleSaveClick();
                    }
                }
            }]}
        >
            <article className="">
                <main className="" key={rerender}>

                    {loading && <Loading />}
                    {!loading && (
                        <div className="">
                            <Form.Group>
                                <Row>
                                    <Col lg="3">
                                        <Form.Label>Zichtbare naam {fieldValue.isSystem}</Form.Label>
                                    </Col>
                                    <Col lg="3">
                                        <TextBox
                                            type="input"
                                            defaultValue={fieldValue.alias}
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                if (isCreate && !fieldValue.columnType) {
                                                    tempFieldValue["columnType"] = columnTypes.find(
                                                        (t) => t.id === 1
                                                    );
                                                    setFieldValue(tempFieldValue);
                                                }
                                                if (e.value.trim() !== "") {
                                                    e.element.classList.remove("bl-mandatory-field");
                                                } else {
                                                    e.element.classList.add("bl-mandatory-field");
                                                }

                                                tempFieldValue["alias"] = e.value;
                                                if (isCreate) {
                                                    let correctedValue = e.value.replace(/ /g, "_");

                                                    // tempFieldValue["name"] = correctedValue;
                                                    setFieldName(correctedValue);
                                                }
                                                setFieldValue(tempFieldValue);
                                                checkChanges();
                                            }}
                                            className={
                                                fieldValue.alias && fieldValue.alias.trim() !== ""
                                                    ? ""
                                                    : "bl-mandatory-field"
                                            }
                                            valueChangeEvent="keyup"
                                        />
                                    </Col>
                                    <Col lg="3">
                                        <Form.Label>Naam in tabel</Form.Label>
                                    </Col>
                                    <Col lg="3">
                                        <TextBox
                                            type="input"
                                            value={fieldName}
                                            className="bl-readonly-field"
                                            placeholder="Naam wordt gegenereerd"
                                            readOnly={true}
                                        />
                                    </Col>

                                </Row>
                            </Form.Group>



                            <Form.Group>
                                <Row>
                                    <Col lg="3">
                                        <Form.Label>Type</Form.Label>
                                    </Col>
                                    <Col lg="3">

                                        <SelectBox
                                            searchEnabled
                                            items={columnTypes}
                                            readOnly={fieldValue.type}
                                            defaultValue={

                                                fieldValue.type
                                                    ? fieldValue.columnType.id
                                                    : 1
                                            }
                                            // readOnly={
                                            //   fieldValue.columnType && fieldValue.columnType.name
                                            //     ? true
                                            //     : false
                                            // }


                                            valueExpr="id"
                                            displayExpr="name"
                                            onValueChange={handleTypeChange}

                                        // readOnly={true}
                                        />
                                    </Col>
                                    <Col lg="3">
                                        <Form.Label>Unieke (id) waarde</Form.Label>
                                    </Col>
                                    <Col lg="3">
                                        <TextBox
                                            type="input"
                                            value={fieldId}
                                            className="bl-readonly-field"
                                            placeholder="Naam wordt gegenereerd"
                                            readOnly={true}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col lg="3">
                                        <Form.Label>RegEx</Form.Label>
                                    </Col>
                                    <Col lg="3">
                                        <TextBox
                                            type="input"
                                            defaultValue={fieldValue["regex"]}
                                            placeholder="RegEx"
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue["regex"] = e.value;
                                                setFieldValue(tempFieldValue);
                                                checkChanges();
                                            }}
                                            readOnly={isSystemColumn}
                                        />
                                    </Col>
                                    <Col lg="3">
                                        <Form.Label>Systeemveld (alleen lezen)</Form.Label>
                                    </Col>

                                    <CheckBox
                                        defaultValue={ /* isCreate ||  */fieldValue.isSystemColumn}
                                        readOnly={true}
                                    />
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col lg="12">
                                        <Form.Label>Memoveld </Form.Label>
                                    </Col>
                                    <Col lg="12">
                                        <TextArea
                                            height={100}
                                            defaultValue={decodeURIComponent(fieldValue["memoField"] ?? "")}
                                            placeholder="Voeg een tooltip toe..."
                                            valueChangeEvent="keyup"
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue["memoField"] = encodeURIComponent(e.value);
                                                setFieldValue(tempFieldValue);
                                                checkChanges();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col lg="3">
                                        <Form.Label>Kopieerbaar </Form.Label>
                                    </Col>
                                    <Col lg="3">
                                        <CheckBox
                                            defaultValue={ /* isCreate ||  */fieldValue.copyable}
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue["copyable"] = e.value;
                                                setFieldValue(tempFieldValue);
                                                checkChanges();
                                            }}
                                            readOnly={isSystemColumn}
                                        />

                                    </Col>
                                </Row>
                            </Form.Group>

                            <div className="column-type-wrapper" key={conditionalField}>
                                {/* <p>{conditionalField}</p> */}

                                {conditionalField === "Geen" && (
                                    <StandaardVeld
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}
                                {conditionalField === "Bestand" && (
                                    <StandaardVeld
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {conditionalField === "Guid" && (
                                    <StandaardVeld
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}
                                {conditionalField === "Handtekening" && (
                                    <StandaardVeld
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {conditionalField === "HTML editor" && (
                                    <StandaardVeld
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {conditionalField === "HTML editor geavanceerd" && (
                                    <StandaardVeld
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {conditionalField === "HTML viewer" && (
                                    <StandaardVeld
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {conditionalField === "Tekst" && (
                                    <TextboxField
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {conditionalField === "Aan/Uit" && (
                                    <OnOffField
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        columnTypes={columnTypes}
                                        isCreate={isCreate}
                                    />
                                )}

                                {conditionalField === "Actieknop" && (
                                    <ActionButtonField
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        datasetId={datasetId}
                                        isCreate={isCreate}
                                    />
                                )}

                                {(conditionalField === "Afhankelijk van andere kolom" ||
                                    conditionalField ===
                                    "Afhankelijk van andere kolom zoekbaar") && (
                                        // <DependendOnOtherColumn
                                        //   fieldValue={fieldValue}
                                        //   setFieldValue={setFieldValue}
                                        //   checkChanges={checkChanges}
                                        //   isCreate={isCreate}
                                        //   currentDataset={currentDataset}
                                        // />

                                        <AVAK
                                            fieldValue={fieldValue}
                                            setFieldValue={setFieldValue}
                                            checkChanges={checkChanges}
                                            isCreate={isCreate}
                                            datasetId={datasetId}
                                            currentDataset={currentDataset}
                                        />
                                    )}


                                {conditionalField === "Afhankelijk van enkel veld" && (
                                    <DependendOnSingleColumn
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                        datasetId={datasetId}
                                    />
                                )}

                                {conditionalField === "Autonummering" && (
                                    <Autonummering
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}

                                {conditionalField === "Berekend" && (
                                    <Berekend
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}

                                {/* type='Bestand' krijgt geen eigen component */}

                                {conditionalField === "Datum" && (
                                    <Datum
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                        setData={setData}
                                    />
                                )}

                                {conditionalField === "DatumTijd" && (
                                    <DatumTijd
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                        setData={setData}
                                    // isSystemColumn={isSystemColumn}
                                    />
                                )}

                                {conditionalField === "Decimaal" && (
                                    <Decimal
                                        isCreate={isCreate}
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {conditionalField === "Email" && (
                                    <Email
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}

                                {conditionalField === "Euro" && (
                                    <Euro
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {conditionalField === "Gebruiker" && userTableId && (
                                    <Gebruiker
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                        userTableId={userTableId}
                                    />
                                )}

                                {conditionalField === "Ja/Nee" && (
                                    <Gebruiker
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                    />
                                )}

                                {/* {conditionalField === "Keuzelijst" && (
                                    <Keuzelijst
                                        multiple={true}
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )} */}

                                {conditionalField === "Keuzelijst multiple" && (
                                    <Keuzelijst
                                        multiple={true}
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}

                                {conditionalField === "Kleur" && (
                                    <Kleur
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}

                                {conditionalField === "Memo" && (
                                    <Memo
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                        columnTypes={columnTypes}
                                    />
                                )}
                                {conditionalField === "Numeriek" && (
                                    <Numeriek
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}

                                {conditionalField === "Samengesteld" && (
                                    <Samengesteld
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}
                                {/* {conditionalField === "Zoekbare keuzelijst" && (
                                    <ZoekbareKeuzelijst
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )} */}
                                {conditionalField === "FTP" && (
                                    <FTP
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}
                                {conditionalField === "Percentage" && (
                                    <Percentage
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                    />
                                )}
                                {conditionalField === "Selectielijst" && userTableId && (
                                    <Selectielijst
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        checkChanges={checkChanges}
                                        isCreate={isCreate}
                                        datasetId={datasetId}
                                        currentDataset={currentDataset}
                                        userTableId={userTableId}
                                        isSystemColumn={isSystemColumn}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </main>
            </article>

            {
                throwNotification && (
                    <UserLeave
                        handleCancel={() => setThrowNotification(false)}
                        handleSaveAndContinue={handleSaveAndContinue}
                        handleDiscardAndContinue={handleDiscardAndContinue}
                    />
                )
            }

        </Popup >
    );
};

export default EditVeld;

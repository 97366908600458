import { Column, RequiredRule, } from "devextreme-react/data-grid";
import { ColorBox } from "devextreme-react";

const onValueChanged = (cell, e) => {
    cell.setValue(e.value);
}

const renderColorColumnCell = ({ value }) => {
    return (
        <div
            style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
            }}
        >
            <div
                style={{
                    backgroundColor: value,
                    width: "20px",
                    margin: "auto 0",
                    display: "inline-block",
                    height: "20px",
                    borderRadius: "4px",
                    border: value ? "1px solid #ddd" : "",
                    marginRight: "10px",
                }}
            />
            <span></span>
        </div>
    );
};

const renderColorColumnEdit = (cell) => {
    //check the mandatory
    const localOnSelectionChanged = onValueChanged.bind(this, cell);
    return (
        <div>
            <ColorBox
                defaultValue={cell.value}
                //onValueChanged={(cell, e) => cell.setValue(e.value)}
                onValueChanged={localOnSelectionChanged}
                //applyValueMode="instantly"
                showClearButton={true}
            />
        </div>
    );
};

const renderColorColumn = ({ name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex, presetFilterValue }) => {
    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }

    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];
    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            caption={alias}
            allowSearch={false}
            allowEditing={!readonly}
            mandatory={mandatory}
            width={columnWidth}
            cellRender={renderColorColumnCell}
            editCellRender={renderColorColumnEdit}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={false}
            groupIndex={groupIndex}
            filterValue={filterValueOfColumn}
            selectedFilterOperation={operatorOfColumn}
        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};

export default renderColorColumn;

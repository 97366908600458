import { Column, RequiredRule, } from "devextreme-react/data-grid";
import FileField from "../components/fileField";

const renderFileColumnCell = (columnId, ftp, tableId, overviewId, inlineEditMode, refreshGrid) => (data) => {
    return (
        <FileField
            value={data.value}
            binary={data.data[`thumbnail_${columnId}`]}
            ftp={ftp}
            columnId={columnId}
            tableItemId={data.data.id}
            tableId={tableId}
            overviewId={overviewId}
            inlineEditMode={inlineEditMode}
            refreshGrid={refreshGrid}
        />
    );
};
//return renderFileColumn(name, alias, columnWidth, columnId, columnId, readonly, mandatory, columnTypeCode === 'FTP', inlineEditMode, tableId, overviewId, refreshGrid, sortIndex, sortDirection, groupIndex);
const renderFileColumn = ({ name, alias, columnWidth, columnId, readonly, mandatory, ftp, inlineEditMode, tableId, overviewId, refreshGrid, sortIndex, sortDirection, groupIndex, presetFilterValue }) => {
    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && !Array.isArray(presetFilterValue[0]) && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }
    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];
    return (
        <Column
            alignment="center"
            key={columnId}
            dataField={name.toLowerCase()}
            cellRender={renderFileColumnCell(columnId, ftp, tableId, overviewId, inlineEditMode, refreshGrid)}
            caption={alias}
            allowSearch={true}
            width={columnWidth}
            allowEditing={false}
            mandatory={mandatory}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={false}
            groupIndex={groupIndex}
            filterValue={filterValueOfColumn}
            selectedFilterOperation={operatorOfColumn}
        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};

export default renderFileColumn;

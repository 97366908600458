import { Column } from "devextreme-react/data-grid";
import { ColumnDisplayValueCalculated } from "../../../enums/columnDisplayValue"
import { getFormatter } from '../../formats';

const renderCalculatedColumn = ({ name, alias, columnWidth, columnId, inlineEditMode, displayValueId, sortIndex, sortDirection, groupIndex, presetFilterValue }) => {
    let format;
    switch (displayValueId) {
        case ColumnDisplayValueCalculated.NUM:
            {
                break;
            }
        case ColumnDisplayValueCalculated.Precision1:
            {
                //format = ',##0.0';
                format = getFormatter('1');
                break;
            }
        case ColumnDisplayValueCalculated.Precision2:
            {
                //format = ',##0.00';
                format = getFormatter('2');
                break;
            }
        case ColumnDisplayValueCalculated.Precision3:
            {
                //format = ',##0.000';
                format = getFormatter('3');
                break;
            }
        case ColumnDisplayValueCalculated.EUPrecison2:
            {
                format = getFormatter('EURO2');
                break;
            }
        case ColumnDisplayValueCalculated.EUPrecison3:
            {
                format = getFormatter('EURO3');
                break;
            }
        default:
            format = null;
    }

    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && !Array.isArray(presetFilterValue[0]) && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }

    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];

    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            format={format}
            alignment="right"
            caption={alias}
            allowSearch={true}
            dataType="number"
            allowEditing={false}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={false}
            groupIndex={groupIndex}
            width={columnWidth}
            cssClass={inlineEditMode ? "disabled-column-controls" : ""}
            filterValue={filterValueOfColumn}
            selectedFilterOperation={operatorOfColumn}
        />
    );
};

export default renderCalculatedColumn;

import { Column, RequiredRule, } from "devextreme-react/data-grid";
import { ColumnValidationCurrency } from "../../../enums/columnValidation";
import { getFormatter } from '../../formats';

const renderCurrencyColumn = ({ name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, displayFormat, columnValidationId, sortIndex, sortDirection, groupIndex, presetFilterValue }) => {

    const currencyFormat = getFormatter(columnValidationId === ColumnValidationCurrency.Precision3 ? "EURO3" : "EURO2");
    const editorOptions = { format: currencyFormat };

    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && !Array.isArray(presetFilterValue[0]) && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }

    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];


    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            format={currencyFormat}
            alignment="right"
            caption={alias}
            allowSearch={true}
            allowEditing={!readonly}
            mandatory={mandatory}
            width={columnWidth}
            editorOptions={editorOptions}
            dataType="number"
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            filterValue={filterValueOfColumn}
            selectedFilterOperation={operatorOfColumn}

        >
            {mandatory && <RequiredRule />}
        </Column>

    );
};

export default renderCurrencyColumn;

import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import appSettings from "../../../appsettings";
import ModalControls from "../parts/modalControls/ModalControls";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import msalFetch from "../../../api/MsalFetch.js";
import toast, { Toaster } from "react-hot-toast";
import { useModalContext } from "../../../context/modalContext";
import { CheckBox, SelectBox, TextBox } from "devextreme-react";
import Loading from "../../loading/Loading";

const AddSearch = () => {
    const msal = useMsal();
    const [changeInForm, setChangeInForm] = useState(false);
    const [form, setForm] = useState();
    const [datasets, setDatasets] = useState();
    const [loading, setLoading] = useState(true);
    const [hasColumns, setHasColumns] = useState(false);
    const [columns, setColumns] = useState();
    const [columnLoading, setColumnLoading] = useState(false);

    const {
        setIsEditing,
        setFormInValidNotification,
        setNotificationData,
        isEditing,
    } = useModalContext();
    console.log(form);
    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const getDatasets = async () => {
        setLoading(true);
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/dataset`
            );
            const data = await response.json();
            console.log(data);
            setDatasets(data);
            setLoading(false);
            return;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const getColumns = async (columnId) => {
        setHasColumns(false);
        setColumnLoading(true);
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/dataset/${columnId}/column`
            );
            const data = await response.json();
            console.log(data);
            setColumns(data);
            setHasColumns(true);
            setColumnLoading(false);
            return;
        } catch (error) {
            setHasColumns(false);
            setColumnLoading(false);
            console.log(error);
            throw error;
        }
    };
    const saveFunction = async () => {
        let allDocument = document.getElementsByClassName("bl-mandatory-field");
        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }

        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/search`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(form),
                }
            );
            if (res.ok) {
                setChangeInForm(false);
                setIsEditing(false);
                return toast.success("De gegevens zijn successvol opgeslagen...");
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }

        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };
    const [throwNotification, setThrowNotification] = useState(false);
    const handleExitClick = () => {
        if (changeInForm) {
            setThrowNotification(true);
        } else {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        getDatasets();
        setForm({ columnId: null, datasetId: null });
    }, [isEditing]);
    if (loading) {
        return <Loading />;
    } else {
        return (
            <main className="bl-modal-inner">
                <ModalControls
                    modalTitle="Zoek kolom"
                    changeInForm={changeInForm}
                    handleSaveClick={saveFunction}
                    hasExitFunction={true}
                    handleExitClick={handleExitClick}
                    throwNotification={throwNotification}
                />
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Tabel</Form.Label>
                        </Col>
                        <Col lg="12">
                            <SelectBox
                                searchEnabled
                                items={datasets}
                                valueExpr="id"
                                displayExpr="tableName"
                                className="bl-mandatory-field"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["datasetId"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                    getColumns(e.value);
                                    e.element.classList.remove("bl-mandatory-field");
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Kolom</Form.Label>
                        </Col>
                        <Col lg="12">
                            <SelectBox
                                searchEnabled
                                items={columns}
                                valueExpr="id"
                                displayExpr="alias"
                                readOnly={hasColumns ? false : true}
                                className="bl-mandatory-field"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["columnId"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                    console.log(tempForm);
                                    e.element.classList.remove("bl-mandatory-field");
                                }}
                            />
                            {columnLoading && <Loading />}
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Weergeven</Form.Label>
                        </Col>
                        <Col lg="12">
                            <CheckBox
                                defaultValue={true}
                                onValueChanged={(e) => {
                                    console.log(e.value);
                                    let tempForm = form;
                                    tempForm["display"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Titel</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="tabel: [table] kolom: [column] aantal: [nrOfItems]"
                                type="input"
                                defaultValue={"Gevonden in tabel [table] kolom [column] ([nrOfItems])"}
                                // defaultValue={form['title']}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["title"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Where</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox type="input"
                                defaultValue={form['where']}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["where"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Order by</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox type="input"
                                defaultValue={form['orderBy']}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm['orderBy'] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </main>
        );
    }
};
export default AddSearch;

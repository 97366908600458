import React, { useState, useCallback, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import appSettings from "../../../appsettings.js";
import toast from "react-hot-toast";
import {
    DataGrid,
    Column,
    Editing,
    Paging,
    Pager,
    FilterRow,
} from "devextreme-react/data-grid";

const RoleOverview = ({
    roleId
}) => {

    const loadOverviews = useCallback(async (loadOptions) => {


        if (roleId == null)
            return;
        const url = `${appSettings.api.endpoint}/api/configurator/overviewsbyrole/${roleId}`;
        const response = await msalFetch(null, url);
        const json = await response.json();
        console.log("overviewsbyrole - JSON", json);
        return json;
    }, [roleId]);

    const updateServerRecord = useCallback(async (rec, val) => {
        console.log("RoleOverview - updateServerRecord - rec - val", rec, val);
        try {
            let body = {
                roleId: roleId,
                overviewId: rec.overviewId,
                value: val[Object.keys(val)[0]]
            };

            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/role/updateoverviewvisibility/${roleId}/${body.overviewId}/${body.value}/`
                // {
                //     method: "POST",
                //     headers: { "Content-type": "application/json" },
                //     body: JSON.stringify(body),
                // }
            );
            console.log("Overview updateServerRecord - res", res);
            toast.success("Toegang bijgewerkt");
        } catch (error) {
            toast.error("error");
            throw error;
        }
    }, [roleId])

    const dataSource = useMemo(() => new DataSource({
        store: new CustomStore({
            load: loadOverviews,
            update: updateServerRecord,
            //remove: deleteServerRecord,
        })
    }), [loadOverviews, updateServerRecord]);

    const onEditingStart = useCallback((e) => {
        if (e.data.overviewId == null)
            e.cancel = true;
    }, []);

    return <div>

        <DataGrid
            title="Lijsten"
            dataSource={dataSource}
            rowAlternationEnabled
            showColumnLines={false}
            showRowLines={true}
            onEditingStart={onEditingStart}
            noDataText="Klik op een rol om de rechten in te stellen"
            height={"70vh"}
        >
            <Paging defaultPageSize={25} />
            <Pager
                visible={true}
                showPageSizeSelector={true}
                showInfo={true}
                allowedPageSizes={[25, "all"]}
                showNavigationButtons={true}
            />
            <FilterRow
                visible={true}
                applyFilter={'auto'} />
            <Editing
                mode="cell"
                allowUpdating={true}
                refreshMode={"repaint"}
            />

            <Column
                allowEditing={false}
                caption="Tabelnaam"
                dataField="tableName"
                dataType="string"
                defaultSortOrder="asc"
                sortIndex={1}
                allowSorting={false}
            />
            <Column
                allowEditing={false}
                caption="Lijst"
                dataField="overviewName"
                dataType="string"
                defaultSortOrder="asc"
                sortIndex={2}
                allowSorting={false}
            />
            <Column
                allowEditing={true}
                caption="Zichtbaar"
                dataType="boolean"
                dataField="visible"
            />
            <Column
                allowEditing={true}
                caption="Standaard lijst"
                dataType="boolean"
                dataField="defaultOverview"
            />
        </DataGrid>
    </div>
}

export default RoleOverview;

import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import { getFormat, formatBytes } from "../../formats.js"

import DataGrid, {
  Column,
  Lookup,
  FilterRow,
  HeaderFilter,
  Paging,
  Pager,
  Summary,
  TotalItem,
  Scrolling,
} from "devextreme-react/data-grid";
import Loading from "../../loading/Loading";
import "./dataset.scss";
import { Link, useHistory } from "react-router-dom";
import { useModalContext } from "../../../context/modalContext";
import BloomFooter from "../../footer/BloomFooter";


const Datasets = () => {
  const msal = useMsal();
  const [loading, setLoading] = useState(true);
  const { setModal, setIsEditing } = useModalContext();

  const link = useHistory();

  const [datasets, setDatasets] = useState();

  const allowedPageSizes = [10, 20, 50, "all"];

  const getDataset = async () => {
    setLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/`
      );
      const data = await response.json();
      setDatasets(data);

      console.log("size", data.readonly)

      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      throw "Data Loading Error";
    }

  };

  useEffect(() => {
    // getData();
    getDataset();
  }, []);

  const handleAddTableClick = (e) => {
    e.preventDefault();
    setModal("ADDTABLE");
    setIsEditing(true);
  };

  function addNewButton() {
    return (
      <div className="bl-config-btn-wrapper">
        <div
          className="bl-btn bl-add-btn bl-config-header-btn"
          onClick={handleAddTableClick}
          title="Nieuwe tabel toevoegen"
        >
          <i className="fas fa-plus" />
        </div>
      </div>
    );
  }

  const cellRender = useCallback((e) => {

    return e.data.isSystemTable ? `${e.displayValue} [Systeem]` : e.displayValue;
  }, []);


  return (
    <main className="bl-content">
      <header className="bl-configurator-title-header">
        <h2>Tabellen</h2>
      </header>
      <section className="bl-card">
        {loading ? (
          <Loading />
        ) : (
          <DataGrid
            height="80vh"
            dataSource={datasets}
            showBorders={true}
            remoteOperations={false}
            repaintChangesOnly={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            showColumnLines={false}
            onRowClick={(e) => {
              link.push(`/configurator/dataset/${e.data.id}`);
            }}
          >
            <FilterRow visible={true} showAllText="Beide" />
            <Paging defaultPageSize={100} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode="full"
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Scrolling mode="standard" />
            <HeaderFilter visible={true} allowSearch={true} />
            <Column
              dataField="tableName"
              dataType="string"
              caption="Naam"
              cellRender={cellRender}

            />
            <Column
              dataField="nrOfRecords"
              dataType="number"
              caption="Items"
              format={"#,##0.##"}
              allowSearch={false}
              allowEditing={false}
              allowFiltering={false}

            />
            <Column
              dataField={"nrOfBytes"}
              dataType="number"
              caption="Grootte"
              format={"#,##0.##"}
              allowSearch={false}
              allowEditing={false}
              allowFiltering={false}
              customizeText={(e) => {
                return formatBytes(e.value, 3);
              }}

            />

            <Column
              dataField="readonly"
              caption="Alleen lezen"
              allowSearch={false}
              allowEditing={false}
              allowFiltering={false}
              dataType="boolean"
              alignment="center"
              width={150}
            >

            </Column>
            <Column
              width={80}
              alignment="center"
              cellRender={editButton}
              headerCellRender={addNewButton}
            />
            <Summary>
              <TotalItem
                column="nrOfRecords"
                valueFormat={"#,##0.##"}
                summaryType="sum"
              />

              <TotalItem
                column="nrOfBytes"
                summaryType="sum"
                customizeText={(e) => {
                  return formatBytes(e.value, 3);
                }}
              />


            </Summary>
          </DataGrid>
        )}
      </section>
      <BloomFooter />
    </main>
  );
};

function editButton(data) {
  return (
    <Link
      to={`/configurator/dataset/${data.data.id}`}
      className="bl-column-controls"
    >
      <i className="fas fa-pencil-alt bl-edit-pencil bl-column-control-btn bl-column-edit-btn" />
    </Link>
  );
}

export default Datasets;

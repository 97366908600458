import React, { useMemo, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
import "./userNav.scss";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import { formatDateTime } from "../formats";
import { useGlobalContext } from "../../context/context";
import { Autocomplete } from "devextreme-react";
import { DropDownOptions, Position } from "devextreme-react/lookup";
import { Spinner } from "react-bootstrap";

const NavSearch = () => {
    const [searchValue, setSearchValue] = useState();
    const { push } = useHistory();
    const { isMobile } = useGlobalContext();
    const [loading, setLoading] = useState(false);


    const loadDataSource = useCallback(async (loadOptions) => {
        if (!loadOptions.searchValue || loadOptions.searchValue.length <= 1) {
            return [];
        }
        setLoading(true);
        const result = [];
        const res = await msalFetch(null,
            `${appSettings.api.endpoint}/api/search/50`, // 100 is the limit
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({
                    searchQuery: searchValue,
                }),
            }
        );
        const data = await res.json();
        const convertToGroupItem = (key, index) => {
            const firstItem = data[key].dataTable[0];
            const item = {
                key: `${firstItem.originalTableName} (${firstItem.columnName})`,
                title: `${firstItem.title}`,
                tableName: `${firstItem.originalTableName}`,
                tableColumnName: `${firstItem.columnName}`,
                nrOfItems: data[key].dataTable.length,
                items: data[key].dataTable.map((recordItem) => {
                    const formattedBlModified = formatDateTime(new Date(`${recordItem.bl_modified}`));

                    return {
                        id: recordItem.tableItemId,
                        name: `${recordItem.resultString}`,
                        detailViewId: recordItem.detailViewId,
                        modified: `${formattedBlModified}`,

                    }
                })
            };
            result.push(item);
        };
        const groupsWithItems = Object.keys(data).filter(key => data[key].dataTable.length > 0);
        groupsWithItems.forEach(convertToGroupItem);
        setLoading(false);
        return result;
    }, [searchValue, setLoading]);

    const dataSource = useMemo(() => new DataSource({
        store: new CustomStore({
            useDefaultSearch: false,
            key: 'tableItemId',
            load: loadDataSource,
        })

    }), [loadDataSource]);

    function GroupTemplate(item) {

        const customTitle = item.title === '' ? (`${item.tableColumnName} van ${item.tableName} (${item.nrOfItems})`) : (item.title.replace('[table]', item.tableName).replace('[column]', item.tableColumnName).replace('[nrOfItems]', item.nrOfItems));
        return <div className="navsearch-result">{customTitle}</div>;
    }

    const itemRender = useCallback((data, index) => {
        return <div className="navsearch-resultitem"><p>{data.name}</p><em>{data.modified === 'INVALID' ? '' : data.modified}</em></div>;
    }, []);


    const navSearchAttributes = {
        id: 'navSearch',
        class: 'nav-search'
    }

    const itemClick = useCallback((item) => {
        setSearchValue(null);
        push(`/detail/${item.itemData.detailViewId}/${item.itemData.id}`);
    }, [push]);

    return (<div className={`${isMobile ? "list-container-mobile" : "list-container"}`}>
        <Autocomplete // eslint-disable-line jsx-a11y/no-access-key
            value={searchValue}
            elementAttr={navSearchAttributes}
            placeholder="Zoeken"
            width={isMobile ? "100%" : "300px"}
            accessKey="z"
            hint="Zoek hier in de geconfigureerde kolommen (alt + z)"
            dataSource={dataSource}
            showClearButton={true}
            grouped={true}
            groupRender={GroupTemplate}
            valueChangeEvent="keyup"
            onValueChanged={(e) => {
                setSearchValue(e.value);
            }}
            displayValue="resultString"
            itemRender={itemRender}
            wrapItemText
            onItemClick={itemClick}
            minSearchLength={2}
            maxLength={35}

            noDataText={`Resultaten voor '${searchValue}'`}
        >
            <DropDownOptions
                minHeight={`${searchValue ? "250px" : "0px"}`}
                height={isMobile ? "auto" : "500px"}
                visible={searchValue && !loading}
                showTitle={true}

                width={isMobile ? "80vw" : "600px"}
                resizeEnabled={!isMobile}

                animation={"pop"}
                title={`${searchValue ? `Zoekterm: ${searchValue}` : ""}`}
                closeOnOutsideClick={true}
                dragEnabled={true}

            >
                <Position
                    my="right top"
                    at="right bottom"
                    of=".nav-search"
                    offset="-20 20"
                />
            </DropDownOptions>
        </Autocomplete>
    </div>
    )

};

export default NavSearch;

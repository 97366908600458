import { Column } from "devextreme-react/data-grid";

//overweeg om 'm bij edit in edit te openen en anders in readonly

const renderHtmlCell = (columnData) => {
    var color = `${(columnData.value ? '#ff9727' : '#ababab')}`;
    return <i className="fa-solid fa-file-code" style={{ color: color, }} />
}

const renderHtmlColumn = ({ name, alias, columnWidth, columnId, inlineEditMode, presetFilterValue }) => {
    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={false}
            dataType="string"
            allowEditing={true}
            width={columnWidth}
            cellRender={renderHtmlCell}
            columnId={columnId}
            cssClass={inlineEditMode ? "disabled-column-controls" : ""}
        />
    );
};

export default renderHtmlColumn;

import { Column, RequiredRule, } from "devextreme-react/data-grid";

const renderStringColumn = ({ name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, allowHeaderFiltering, alignment, groupIndex, presetFilterValue }) => {
    // if (presetFilterValue != null)
    //     debugger;
    //console.log("renderStringColumn", "presetFilterValue", presetFilterValue);
    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && !Array.isArray(presetFilterValue[0]) && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }

    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];
    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={true}
            dataType="string"
            allowEditing={!readonly}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            groupIndex={groupIndex}
            allowGrouping={true}
            showWhenGrouped={true}
            width={columnWidth}
            mandatory={mandatory}
            allowHeaderFiltering={allowHeaderFiltering}
            alignment={alignment}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            filterValue={filterValueOfColumn}
            selectedFilterOperation={operatorOfColumn}
        >
            {mandatory && <RequiredRule />}
        </Column>)
};

export default renderStringColumn;

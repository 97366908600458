import { Column, RequiredRule, HeaderFilter } from "devextreme-react/data-grid";


const renderDateTimeColumn = ({ name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, includeTime, sortIndex, sortDirection, groupIndex, presetFilterValue }) => {
    //console.log("renderDateTimeColumn", "presetFilterValue", presetFilterValue);
    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && !Array.isArray(presetFilterValue[0]) && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }

    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let selectedFilterOperation = null;
    let filterValue = null;
    let filterValues = null;
    if (operatorOfColumn === 'anyof') {
        //top row selection
        filterValues = filterEntryOfColumn[2];
    } else {
        //bottom row selection
        let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];
        if (filterValueOfColumn != null) {
            if (Array.isArray(filterValueOfColumn)) {
                //case of between two dates
                filterValueOfColumn = [filterValueOfColumn[0] == null ? null : new Date(filterValueOfColumn[0]), filterValueOfColumn[1] == null ? null : new Date(filterValueOfColumn[1])]
            }
            /*else {
                filterValueOfColumn = new Date(filterValueOfColumn);
            }*/
        }
        //filterValue = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];
        filterValue = filterValueOfColumn;
        selectedFilterOperation = operatorOfColumn;
    }

    // console.log("renderDateTimeColumn", filterEntryOfColumn, "filterValueOfColumn", filterValueOfColumn);

    //console.log("renderDateTimeColumn", "filterValue", filterValue, "filterValues", filterValues, "selectedFilterOperation", selectedFilterOperation);

    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={true}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            dataType={includeTime ? "datetime" : "date"}
            allowEditing={!readonly}
            width={columnWidth}
            format={includeTime ? "dd-MM-yyyy HH:mm" : "dd-MM-yyyy"}
            // allowHeaderFiltering={!includeTime}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={!includeTime}
            groupIndex={groupIndex}
            filterValue={filterValue}
            filterValues={filterValues}
            //filterValues={filterValueOfColumn}
            //selectedFilterOperation={operatorOfColumn}
            selectedFilterOperation={selectedFilterOperation}
        >
            {mandatory && <RequiredRule />}

            <HeaderFilter
                groupInterval={"day"}
            />
        </Column >
    );
};

export default renderDateTimeColumn;

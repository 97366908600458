import ListWrapper from './ListWrapper';
import React, { useState, useEffect } from "react";
import appSettings from "../../appsettings.js";
import msalFetch from "../../api/MsalFetch.js";
import { useParams } from "react-router-dom";

const ListDataFetcher = ({
    inputListId,
    externalSelectFunction,
    isModal,
}) => {
    const { overviewId: overviewIdFromParams } = useParams();
    const overviewId = inputListId ?? overviewIdFromParams;
    const [scrollHorizontal, setScrollHorizontal] = useState();
    const [gridName, setGridName] = useState();
    const [columns, setColumns] = useState([]);
    const [columnFunctions, setColumnFunctions] = useState();
    const [tableId, setTableId] = useState();
    const [keyExpression, setKeyExpression] = useState();
    const [expandDetailViewId, setExpandDetailViewId] = useState();
    const [manualSortColumnId, setManualSortColumnId] = useState();
    const [detailViewIdAdd, setDetailViewIdAdd] = useState();
    const [preferredDetailViewId, setPreferredDetailViewId] = useState();
    const [preferredDetailViewIdEdit, setPreferredDetailViewIdEdit] = useState();
    const [overviewOrControlRelationName, setOverviewOrControlRelationName] = useState();
    const [excelFilename, setExcelFilename] = useState();
    const [renderGrid, setRenderGrid] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState();
    const [allowInlineEditing, setAllowInlineEditing] = useState();
    const [allowCopy, setAllowCopy] = useState();
    const [allowDelete, setAllowDelete] = useState();
    const [allowCreate, setAllowCreate] = useState();
    const [allowRead, setAllowRead] = useState();
    const [allowUpdate, setAllowUpdate] = useState();
    const [allowExportExcel, setAllowExportExcel] = useState();
    const [inlineEditMode, setInlineEditMode] = useState();
    const [readOnly, setReadOnly] = useState();
    const [presetFilters, setPresetFilters] = useState();

    useEffect(() => {
        // reset the inline edit mode when switching to different screen
        setInlineEditMode(false);
    }, [overviewId]);

    // WARNING: this effect can run twice in develop because of StrictMode
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/${overviewId}/`);
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(`[Overview] getOverviewData response was not ok: ${data.message}`);
                }
                setScrollHorizontal(data.scrollHorizontal);
                setGridName(data.name);
                setColumnFunctions(data.columnFunctions);
                setTableId(data.table.id);
                setManualSortColumnId(data.manualChangeOrderByColumnId);
                setAllowCopy(data.allowCopy);
                setAutoRefresh(data.autoRefresh);
                setPreferredDetailViewId(data.preferredDetailViewId);
                setPreferredDetailViewIdEdit(data.preferredDetailViewIdEdit);
                setDetailViewIdAdd(data.preferredDetailViewIdAdd ?? data.preferredDetailViewId);
                setOverviewOrControlRelationName(data.name);
                setExcelFilename(data.name + '.xlsx');
                setColumns(data.columns);
                setExpandDetailViewId(data.expandDetailviewId);
                setKeyExpression(data.columns.find((t) => t.primairyKey).name.toLowerCase());
                setAllowInlineEditing(data.inlineEditing);
                setRenderGrid(overviewId);
                setReadOnly(data.table.readonly)
                setAllowDelete(data.table.permission.delete === 1)
                setAllowCreate(data.table.permission.create === 1)
                setAllowRead(data.table.permission.read === 1)
                setAllowUpdate(data.table.permission.update === 1)
                setAllowExportExcel(data.allowExportToExcel);
                //setPresetFilters(data.overviewFilters);
            } catch (error) {
                // @TODO: do something...
                throw error;
            }
        };
        getData();
    }, [overviewId]);

    if (renderGrid !== overviewId) {
        return <div />;
    }

    return (
        <ListWrapper
            columns={columns}
            overviewId={overviewId}
            scrollHorizontal={scrollHorizontal}
            columnFunctions={columnFunctions}
            tableId={tableId}
            keyExpression={keyExpression}
            isControlRelation={false}
            isOverview={true}
            expandDetailViewId={expandDetailViewId}
            manualSortColumnId={manualSortColumnId}
            detailViewIdAdd={detailViewIdAdd}
            preferredDetailViewId={preferredDetailViewId}
            preferredDetailViewIdEdit={preferredDetailViewIdEdit}
            tableItemId={null}
            excelFilename={excelFilename}
            overviewOrControlRelationName={overviewOrControlRelationName}
            gridName={gridName}
            externalSelectFunction={externalSelectFunction}
            allowInlineEditing={allowInlineEditing}
            inlineEditMode={inlineEditMode}
            setInlineEditMode={setInlineEditMode}
            autoRefresh={autoRefresh}
            tableReadOnly={readOnly}
            allowCopy={allowCopy}
            allowDelete={allowDelete}
            allowCreate={allowCreate}
            allowRead={allowRead}
            allowUpdate={allowUpdate}
            allowExportExcel={allowExportExcel}
            isModal={isModal}
        //presetFilters={presetFilters}
        />
    );
};

export default ListDataFetcher;

import { Column, RequiredRule, } from "devextreme-react/data-grid";
const renderNumberColumn = (name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex, presetFilterValue) => {

    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && !Array.isArray(presetFilterValue[0]) && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }
    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];
    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={true}
            dataType="number"
            allowEditing={!readonly}
            width={columnWidth}
            mandatory={mandatory}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={true}
            groupIndex={groupIndex}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            filterValue={filterValueOfColumn}
            selectedFilterOperation={operatorOfColumn}
        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};

export default renderNumberColumn;

import { Popup } from 'devextreme-react/popup';
import saveAs from "file-saver";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer';
import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings";
import PopupFile from "./PopupFile.js";
import PreviewImage from "../../modals/Previews/PreviewImage";


export const getFileViewUrl = async (columnId, tableItemId) => {
    const res = await msalFetch(null,
        `${appSettings.api.endpoint}/api/thumbnail/${columnId}/${tableItemId}/2`
    );
    const json = await res.json();
    const result = json.base64;
    return result;
};

const strEndsWith = str => ending => (str || "").toLowerCase().endsWith(ending);

const getFileType = (str) => {
    const endsWith = strEndsWith(str);
    switch (true) {
        case endsWith('.jpg'):
        case endsWith('.jpeg'):
        case endsWith('.png'):
        case endsWith('.gif'):
        case endsWith('.webp'):
        case endsWith('.bmp'):
        case endsWith('.tiff'):
            return 'image';
        case endsWith('.xls'):
        case endsWith('.csv'):
            return 'excel';
        case endsWith('.pdf'):
            return 'pdf';
        case endsWith('.ppt'):
            return 'powerpoint';
        case endsWith('.doc'):
        case endsWith('.docx'):
        case endsWith('.odt'):
            return 'word';
        case endsWith('.html'):
        case endsWith('.htm'):
            return 'code';
        case endsWith('.msg'):
            return 'pen';
        default:
            return 'circle-question';
    }
}

const getDownload = (fileType) => {
    switch (fileType) {
        case 'pdf':
            return false;
        case 'code':
            return false;
        case 'image':
            return true;
        default:
            return true;
    }
}

const FileField = ({
    tableId,
    value,
    binary,
    ftp,
    columnId,
    tableItemId,
    inlineEditMode,
    overviewId,
    refreshGrid,
}) => {
    const { ref } = useInView();
    const [imageBase64, setImageBase64] = useState();
    const [fileViewUrl, setFileViewUrl] = useState();
    const [previewUrl, setPreviewUrl] = useState();
    const fileType = getFileType(value);

    const onPopupHideFileView = useCallback((e) => {
        console.log("onPopupHideFileView");
        setFileViewUrl(null);
        refreshGrid();
    }, [refreshGrid])

    const getPreview = useCallback(async (downloadPass) => {
        const download = downloadPass ? true : getDownload(fileType);
        if (ftp) {
            return value;
        }
        let imageObjectURL;

        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/table/downloadfile/${value.replace('&', '[AMPERSAND]')}/?download=${download}` // I dont know what this download boolean is for. It does not seem to change the output, Laurens Kling 2022
            );
            const resData = await res.blob();
            imageObjectURL = URL.createObjectURL(resData);
            return imageObjectURL;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, [value, fileType, ftp]);

    const isImage =
        (value ?? "").toLowerCase().includes(".jpg") ||
        (value ?? "").toLowerCase().includes(".png") ||
        (value ?? "").toLowerCase().includes(".jpeg") ||
        (value ?? "").toLowerCase().includes(".webp");

    useEffect(() => {
        // console.log(`binary ${value} ${tableItemId}`, binary)
        if (binary != null) {
            // TEMPORARY FIX FOR Images without data:image prefix
            // TODO:

            (binary.startsWith('data') ? setImageBase64(`${binary}`) : setImageBase64(`data:image/png;base64, ${binary}`));

            //THIS IS DEFINITIVE OPTION
            //setImageBase64(`${binary}`);
        }
    }, [binary]);

    const onClick = useCallback(async (e) => {
        if (isImage) {
            const atob = await getFileViewUrl(columnId, tableItemId);
            //setFileViewUrl(`data:image/png;base64, ${atob}`);
            //setFileViewUrl(`${atob}`);
            (atob.startsWith('data') ? setFileViewUrl(`${atob}`) : setFileViewUrl(`data:image/png;base64, ${atob}`));
        } else {
            const imageObjectURL = await getPreview();
            setPreviewUrl(imageObjectURL);
        }
    }, [columnId, isImage, tableItemId, getPreview]);

    const downloadFile = useCallback(async (e) => {
        if (ftp) {
            const anchor = document.createElement('a');
            anchor.href = fileViewUrl;
            anchor.download = value.substring(value.lastIndexOf('/') + 1);
            document.body.appendChild(anchor); // Required for Firefox
            anchor.click();
            document.body.removeChild(anchor);
        } else {
            const urlDownload = `${appSettings.api.endpoint}/api/table/downloadfile/${encodeURIComponent(value.replace("____", "'"))}/?download=true`;
            const res = await msalFetch(null, urlDownload);
            const resData = await res.blob();

            const urlBlob = URL.createObjectURL(resData);
            saveAs(urlBlob, value);
        }

    }, [ftp, value, fileViewUrl]);

    return (
        <>
            <div ref={ref}>
                {value == null ? null : (
                    isImage ? (
                        <img src={imageBase64} alt={value} onClick={onClick} />
                    ) : (
                        <span onClick={onClick}>
                            <i
                                className={`fa fa-file${fileType ? `-${fileType} fa-4x p3 primaryAccent` : ""}`}
                            />
                        </span>
                    )
                )}
            </div>
            <Popup
                resizeEnabled={true}
                title={`${value}`}
                visible={fileViewUrl != null}
                onHiding={onPopupHideFileView}
                enableBodyScroll={false}

                dragEnabled={true}
                animation={false}
                width={"auto"}
                height={"auto"}
                minHeight={"10vh"}
                maxHeight="80vh"
                minWidth={"20vw"}
                maxWidth="80vw"
            >
                <div style={{}} >
                    {fileViewUrl && (
                        <PopupFile
                            tableId={tableId}
                            columnId={columnId}
                            tableItemId={tableItemId}
                            overviewId={overviewId}
                            inlineEditMode={false}
                            fileViewUrl={fileViewUrl}
                            setFileViewUrl={setFileViewUrl}
                            downloadFile={downloadFile}
                            refreshGrid={refreshGrid}
                        />
                    )}
                </div>
            </Popup>
            {previewUrl && (
                <PreviewImage
                    ftp={ftp}
                    url={previewUrl}
                    title={value}
                    closeFunction={() => {
                        setPreviewUrl(null);
                    }}
                />
            )}
        </>
    )
};

export default memo(FileField);

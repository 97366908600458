import { React, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import { Tooltip } from 'devextreme-react/tooltip';
import { useGlobalContext } from "../../context/context.js";
import SelectSearchPopup from "../modals/showInputList/SelectSearchPopup";
import { Link } from "react-router-dom";

const FieldLabel = ({
    label,
    value,
    matchesTheRegex,
    readonly,
    mandatory,
    columnId,
    registerLoaded,
    onControlUpdate,
    memoField,
    regex,
    showGoToDetailview,
    preferredDetailviewId,
    inputListId,
    withMagnifyingGlass,
    detailviewId,
    controlPropertyId,
    getColumnByColumnId

}) => {
    const [activeModal, setActiveModal] = useState(false);
    const isString = typeof value === 'string';
    const isTel = value && isString && value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
    const isLink = value && isString && (value.startsWith("www.") || value.startsWith("http"));
    const isEmail = value && isString && (value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/));
    const isMobile = useGlobalContext();
    const webPage = value && isString && (value.startsWith('http') ? value : `https://${value}`);
    const showEmailButton = isEmail;

    const valid = Boolean(value);

    const showDetailViewPicker = useCallback(() => {
        setActiveModal(true);
    }, []);

    const handleSelectionFromModal = useCallback((selection) => {
        const value = selection.primairyKeyValue ?? selection.value;
        onControlUpdate(columnId, String(value));
    }, [onControlUpdate, columnId]);

    return (
        <div className="form-label">
            {/* Tooltip*/}
            {memoField && (<Tooltip
                target={"#tooltip_" + columnId}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
            >
                <div className="control-property-tooltip"><strong>{label}</strong><br></br>{memoField}</div>
            </Tooltip>)}

            {/* SL with a value*/}
            {preferredDetailviewId && Boolean(value) && (
                < div className="control-property-label pagelink">
                    <Link
                        className="control-property-label-link"
                        tabIndex="-1"
                        to={`/detail/${preferredDetailviewId}/${value}`}
                        target="_self"
                        rel="noreferrer"
                        title={`Ga naar ${label.toLowerCase()}`}
                    >
                        {label}
                        <i title={`Ga naar ${label.toLowerCase()}`} className="fa-regular fa-arrow-up-right-from-square hyperlink" />
                    </Link>
                </div>
            )}

            {/* */}
            {((!preferredDetailviewId && !isLink) || !value) && (
                <div className={`control-property-label ${isMobile ? "mobile" : ""}`}>
                    {label}
                </div>
            )}

            {/* Textbox with link to website */}
            {isLink && (
                <div className="control-property-label pagelink">
                    <a className="control-property-label-link" href={webPage} target="_blank" rel="noreferrer">
                        {label}
                        <i title={`Ga naar pagina ${value}`} className={"fa-regular fa-arrow-up-right-from-square hyperlink"} />
                    </a>
                </div>
            )}
            {/* Phonenumber in textbox */}
            {isTel && (
                <div>
                    <a className="control-property-label-icon hyperlink" href={`tel:${value}`} title={`${value} bellen`}>
                        <i className={"control-property-label-icon fa-regular fa-phone"} />
                    </a>
                </div>
            )}
            {/* Mandatory without a value */}
            {(mandatory && !value) && (
                <div>
                    <i title={`${label} is verplicht`} className={"control-property-label-icon fa-regular fa-circle-exclamation"} />
                </div>
            )}
            {/* Regex check */}
            {regex && (
                <div className="control-property-label-icon">
                    <i
                        title={`${matchesTheRegex ? "Voldoet aan de eisen" : "Voldoet niet aan de eisen"}`}
                        className={`${matchesTheRegex ? "fa-regular fa-circle-check" : "fa-regular fa-circle-exclamation"}`}
                    />
                </div>
            )}
            {/* Email address detected */}
            {showEmailButton && (
                <div >
                    <a className="control-property-label-icon" href={`mailto:${value}`}>
                        <i title={`E-mail sturen naar ${value}`} className={"fa-regular fa-envelope"} />
                    </a>
                </div>
            )}
            {/* Has memofield shows the icon */}
            {memoField && (
                <div className="control-property-label-icon">
                    <i id={"tooltip_" + columnId} className={"control-property-label-icon fa-regular fa-circle-info"} />
                </div>
            )}
            {/* A list/overview is added to the SL for displaying the popup */}
            {withMagnifyingGlass && inputListId !== 0 && (
                <div>
                    <button className="control-property-label-icon" onClick={showDetailViewPicker}>
                        <i title={`Zoeken in lijst`} className={"fa-regular fa-magnifying-glass"} />
                    </button>
                </div>
            )}
            {/* This is the popup with the configured list in SL field */}
            {activeModal && (
                <SelectSearchPopup
                    activeModal={activeModal}
                    setActiveModal={setActiveModal}
                    label={label}
                    detailviewId={detailviewId}
                    propertyId={controlPropertyId}
                    handleOptionClick={handleSelectionFromModal}
                    inputListId={inputListId}
                    getColumnByColumnId={getColumnByColumnId}
                    columnId={columnId}
                />
            )}
        </div>
    );
};

export default FieldLabel;

import { Column, RequiredRule, } from "devextreme-react/data-grid";
import { getFormatter } from '../../formats';

const percentageFormat = getFormatter('PERC');

const renderPercentageColumn = ({ name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex, presetFilterValue }) => {
    let filterEntryOfColumn;
    if (Array.isArray(presetFilterValue) && presetFilterValue.length === 3 && !Array.isArray(presetFilterValue[0]) && presetFilterValue[0].toLowerCase() === name.toLowerCase()) {
        filterEntryOfColumn = presetFilterValue;
    } else {
        filterEntryOfColumn = presetFilterValue?.find(item => item[0] === name.toLowerCase());
    }

    let operatorOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[1];
    let filterValueOfColumn = filterEntryOfColumn == null ? null : filterEntryOfColumn[2];
    return (
        <Column
            key={columnId}
            dataField={name.toLowerCase()}
            format={percentageFormat}
            alignment="right"
            caption={alias}
            allowSearch={true}
            allowEditing={!readonly}
            width={columnWidth}
            editorOptions={{ format: percentageFormat }}
            mandatory={mandatory}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={true}
            groupIndex={groupIndex}
            filterValue={filterValueOfColumn}
            selectedFilterOperation={operatorOfColumn}
        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};
export default renderPercentageColumn;

import React, { memo, useState, useCallback, useEffect, useRef, useMemo } from "react";
import { DataGrid, TextBox, SelectBox, CheckBox } from "devextreme-react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import msalFetch from "../../../../api/MsalFetch.js";
import appSettings from "../../../../appsettings.js";
import { Form, Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { Column } from "devextreme-react/data-grid";
import { TagBox } from 'devextreme-react/tag-box';
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';

const PopupEditScheduler = ({
    tableId,
    schedulerId,
    setShowEditScheduler,
    refresh,
}) => {
    const isCreate = schedulerId == null;
    const isEdit = !isCreate;

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [filterOnLoggedInUser, setFilterOnLoggedInUser] = useState(false);
    const [selectedConnectorId, setSelectedConnectorId] = useState();
    const [connectorData, setConnectorData] = useState();
    const [users, setUsers] = useState();
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState(null);
    const [roles, setRoles] = useState(null);
    const [cellDuration, setCellDuration] = useState(30);
    const [startDayHour, setStartDayHour] = useState(8);
    const [endDayHour, setEndDayHour] = useState(18);
    const [selectedDetailViewId, setSelectedDetailviewId] = useState();
    const [detailViews, setDetailviews] = useState();
    const [selectFieldsInTable, setSelectFieldsInTable] = useState();
    const [draggableSelectFieldId, setDraggableSelectFieldId] = useState();
    const [draggableDetailViewId, setDraggableDetailViewId] = useState();
    const [detailViewsInDraggableTable, setDetailViewsInDraggableTable] = useState();
    const [defaultAppointmentLength, setDefaultAppointmentLength] = useState();

    const cellDurations = useMemo(() => [15, 30, 60], []);
    const defaultAppointmentDurations = useMemo(() => [15, 30, 45, 60, 75, 90, 105, 120], []);
    const startDayHours = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], []);
    const endDayHours = useMemo(() => [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24], []);

    const getUsers = useCallback(async () => {
        const response = await msalFetch(null, `${appSettings.api.endpoint}/api/user/getusers/null`, {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify({ searchValue: null }),
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(`[Overview] getOverviewData response was not ok: ${data.message}`);
        }
        setUsers(data);
    }, []);

    const getDetailViews = useCallback(async () => {
        const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/page`, {
            method: "GET",
            headers: { "Content-type": "application/json" }
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(`[Overview] getOverviewData response was not ok: ${data.message}`);
        }
        setDetailviews(data.map(item => { return { id: item.id, name: item.name } }))
        if (isCreate)
            setSelectedDetailviewId(data[0].id)
    }, [tableId, isCreate]);

    const getSelectFieldsInTable = useCallback(async () => {
        const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/table/get-select-fields-of-table/${tableId}`);
        const data = await response.json();
        if (!response.ok) {
            throw new Error(`[SCHEDULER] getSelectFieldsInTable response was not ok: ${data.message}`);
        }
        const items = data
            //.result
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .map(item => ({ id: item.id, name: `${item.alias}` }));
        setSelectFieldsInTable(items);
    }, [tableId]);

    const validationGroupRef = useRef();

    const fetchSchedulerData = useCallback(async () => {
        try {
            const response = await msalFetch(undefined,
                `${appSettings.api.endpoint}/api/scheduler/${schedulerId}`
            );
            const data = await response.json();
            setTitle(data.title);
            setDescription(data.description);
            setFilterOnLoggedInUser(data.filterOnLoggedInUser)
            setSelectedConnectorId(data.connectorId);
            setSelectedUsers(isCreate ? [] : data.schedulerUsers);
            setSelectedRoles(isCreate ? [] : data.schedulerRoles);
            setSelectedUsers(isCreate ? [] : data.schedulerUsers);
            setCellDuration(cellDurations.includes(data.cellDuration) ? data.cellDuration : 30);
            setStartDayHour(startDayHours.includes(data.startDayHour) ? data.startDayHour : 8);
            setEndDayHour(endDayHours.includes(data.endDayHour) ? data.endDayHour : 18);
            setSelectedDetailviewId(data.preferredDetailViewId);
            setDraggableSelectFieldId(data.draggableSelectFieldId);
            setDraggableDetailViewId(data.draggableDetailViewId);
            setDefaultAppointmentLength(data.defaultAppointmentLength);
        } catch (error) {
            throw error;
        }
    }, [schedulerId, isCreate, setSelectedUsers, cellDurations, startDayHours, endDayHours]);

    const fetchConnectorData = useCallback(async () => {
        try {
            const response = await msalFetch(undefined,
                `${appSettings.api.endpoint}/api/configurator/connector/list/${tableId}`
            );
            const data = await response.json();
            setConnectorData(data);
        } catch (error) {
            throw error;
        }
    }, [schedulerId, tableId]);

    const fetchRoles = useCallback(async () => {
        try {
            //api/configurator/role
            const response = await msalFetch(undefined,
                `${appSettings.api.endpoint}/api/configurator/role`
            );
            const data = await response.json();
            setRoles(data);
        } catch (error) {
            throw error;
        }
    }, []);

    const insertScheduler = useCallback(async () => {
        try {
            const response = await msalFetch(undefined,
                `${appSettings.api.endpoint}/api/scheduler/insert`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({
                        title: title,
                        description: description,
                        filterOnLoggedInUser: filterOnLoggedInUser,
                        connectorId: selectedConnectorId,
                        schedulerUsers: selectedUsers ?? [],
                        cellDuration: cellDuration,
                        startDayHour: startDayHour,
                        endDayHour: endDayHour,
                        preferredDetailViewId: selectedDetailViewId,
                        draggableSelectFieldId: draggableSelectFieldId,
                        draggableDetailViewId: draggableDetailViewId,
                        defaultAppointmentLength: defaultAppointmentLength
                    })
                }
            );
            const data = await response.json();
            if (data.ok) {
                toast.success("De agenda is opgeslagen");
            } else {
                toast.error("Er is iets misgegaan");
            }
            setShowEditScheduler(false);
            refresh();
        } catch (error) {
            throw error;
        }
    }, [title, description, filterOnLoggedInUser, selectedConnectorId, setShowEditScheduler, refresh, selectedUsers, cellDuration, startDayHour, endDayHour, selectedDetailViewId, draggableSelectFieldId, draggableDetailViewId, defaultAppointmentLength]);

    const updateScheduler = useCallback(async () => {
        try {
            const response = await msalFetch(undefined,
                `${appSettings.api.endpoint}/api/scheduler/${schedulerId}/update`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({
                        id: schedulerId,
                        title: title,
                        description: description,
                        filterOnLoggedInUser: filterOnLoggedInUser,
                        connectorId: selectedConnectorId,
                        schedulerUsers: selectedUsers ?? [],
                        schedulerRoles: selectedRoles ?? [],
                        cellDuration: cellDuration,
                        startDayHour: startDayHour,
                        endDayHour: endDayHour,
                        preferredDetailViewId: selectedDetailViewId,
                        draggableSelectFieldId: draggableSelectFieldId,
                        draggableDetailViewId: draggableDetailViewId,
                        defaultAppointmentLength: defaultAppointmentLength
                    })
                }
            );
            const data = await response.json();
            if (data.ok) {
                toast.success("De agenda is opgeslagen");
            } else {
                toast.error("Er is iets misgegaan");
            }
            setShowEditScheduler(false);
            refresh();
        } catch (error) {
            throw error;
        }
    }, [schedulerId, title, description, filterOnLoggedInUser, selectedConnectorId, setShowEditScheduler, refresh, selectedUsers, cellDuration, startDayHour, endDayHour, selectedDetailViewId, selectedRoles, draggableSelectFieldId, draggableDetailViewId, defaultAppointmentLength]);

    const onUserSelectionChanged = useCallback((e) => {
        if (e.removedItems.length > 0) {
            setSelectedUsers(selectedUsers.filter(item => !e.removedItems.map(item => item.id).includes(item)));
        } else if (e.addedItems.length > 0) {
            setSelectedUsers([...(selectedUsers ?? []), ...e.addedItems.map(item => item.id)]);
            e.component.field().value = "";
        } else {

        }
    }, [setSelectedUsers, selectedUsers]);

    const [fullScreen, setFullScreen] = useState(false);


    const onRoleSelectionChanged = useCallback((e) => {
        if (e.removedItems.length > 0) {
            setSelectedRoles(selectedRoles.filter(item => !e.removedItems.map(item => item.id).includes(item)));
        } else if (e.addedItems.length > 0) {
            setSelectedRoles([...(selectedRoles ?? []), ...e.addedItems.map(item => item.id)]);
            e.component.field().value = "";
        } else {

        }
    }, [setSelectedRoles, selectedRoles]);

    useEffect(() => {
        if (isEdit) {
            fetchSchedulerData(schedulerId);
        }

    }, [fetchSchedulerData, schedulerId, isEdit]);

    useEffect(() => {
        fetchConnectorData(schedulerId);
    }, [fetchConnectorData, schedulerId]);

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles])

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        getDetailViews();
    }, [getDetailViews]);

    useEffect(() => {
        getSelectFieldsInTable();
    }, [getSelectFieldsInTable]);

    useEffect(() => {
        const innerUseEffect = async () => {
            if (draggableSelectFieldId == null) {
                setDetailViewsInDraggableTable([]);
            } else {
                //api/detailview/get-detailviews-by-column/{id}
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/detailview/get-detailviews-by-column/${draggableSelectFieldId}`);
                const data = await response.json();
                setDetailViewsInDraggableTable(data);
            }
        }
        innerUseEffect();
    }, [draggableSelectFieldId]);

    return (
        <Popup
            className="bl-popup"
            showTitle={true}
            animation={false}
            hideOnOutsideClick={true}
            title="Instellen agenda"
            resizeEnabled={true}
            visible={true}
            showCloseButton={true}
            onHiding={() => { setShowEditScheduler(false); }}
            fullScreen={fullScreen}
            toolbarItems={
                [

                    {
                        toolbar: "bottom",
                        widget: "dxButton",
                        location: "after",
                        options:
                        {
                            icon: 'check',
                            text: 'Opslaan en sluiten',
                            useSubmitBehavior: true,
                            onClick: (e) => {
                                if (!validationGroupRef.current.instance().validate().isValid) {
                                    return;
                                }
                                if (isCreate) {
                                    insertScheduler();
                                } else {
                                    updateScheduler();
                                }
                            }

                        }


                    },
                    {
                        toolbar: "bottom",
                        icon: "email",
                        stylingMode: 'contained',
                        widget: "dxButton",
                        location: "before",
                        options:
                        {
                            icon: 'fullscreen',
                            text: 'Volledig scherm',
                            useSubmitBehavior: false,
                            onClick: (e) => { setFullScreen(!fullScreen) }

                        }


                    }
                ]
            }
        >
            <ValidationGroup ref={validationGroupRef}>

                <Form.Group>
                    <Row>
                        <header className="bl-config-section-header">
                            <Col lg="12">
                                <h3 className="bl-set-title">{title}</h3>
                            </Col>
                        </header>


                    </Row>
                    <Row>
                        <Col lg="4">
                            Titel
                        </Col>
                        <Col lg="4">
                            <TextBox
                                placeholder="Titel"
                                value={title}
                                onValueChanged={(e) => { setTitle(e.value) }}
                                showClearButton={true}
                                valueChangeEvent="input"
                            >
                                <Validator>
                                    <RequiredRule
                                        message="Titel is verplicht" />
                                </Validator>
                            </TextBox>
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Omschrijving
                        </Col>
                        <Col lg="4">
                            <TextBox
                                placeholder="Omschrijving"
                                value={description}
                                onValueChanged={(e) => { setDescription(e.value) }}
                                showClearButton={true}
                                valueChangeEvent="input"
                            ><Validator>
                                    <RequiredRule
                                        message="Omschrijving is verplicht" />
                                </Validator>
                            </TextBox>
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Filter op ingelogde gebruiker
                        </Col>
                        <Col lg="4">
                            <CheckBox
                                value={filterOnLoggedInUser}
                                onValueChanged={(e) => {
                                    setFilterOnLoggedInUser(!filterOnLoggedInUser)
                                }}
                            />

                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Connector
                        </Col>
                        <Col lg="4">
                            <SelectBox
                                valueExpr="id"
                                displayExpr="name"
                                items={connectorData}
                                value={selectedConnectorId}
                                // defaultValue={
                                //     formValue['verticalHeight']
                                // }
                                onValueChanged={(e) => { setSelectedConnectorId(e.value); }}
                            />
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Celduur
                        </Col>
                        <Col lg="4">
                            <SelectBox
                                items={cellDurations}
                                value={cellDuration}
                                onValueChanged={(e) => { setCellDuration(e.value) }}
                            />
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Minimale afspraaklengte
                        </Col>
                        <Col lg="4">
                            <SelectBox
                                items={defaultAppointmentDurations}
                                value={defaultAppointmentLength}
                                onValueChanged={(e) => { setDefaultAppointmentLength(e.value) }}
                            />
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Start van de dag (uur)
                        </Col>
                        <Col lg="4">
                            <SelectBox
                                items={startDayHours}
                                value={startDayHour}
                                onValueChanged={(e) => { setStartDayHour(e.value) }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Eind van de dag (uur)
                        </Col>
                        <Col lg="4">
                            <SelectBox
                                items={endDayHours}
                                value={endDayHour}
                                onValueChanged={(e) => { setEndDayHour(e.value) }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Pagina
                        </Col>
                        <Col lg="4">
                            {detailViews && <SelectBox
                                items={detailViews}
                                value={selectedDetailViewId}
                                valueExpr="id"
                                displayExpr="name"
                                onValueChanged={(e) => { setSelectedDetailviewId(e.value) }}
                            />}
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Gebruikers in deze agenda
                        </Col>
                        <Col lg="4">
                            {(selectedUsers || isCreate) &&
                                <TagBox
                                    showSelectionControls={true}
                                    showClearButton={true}
                                    searchEnabled={true}
                                    showDropDownButton={true}
                                    defaultValue={selectedUsers}
                                    items={users}
                                    valueExpr="id"
                                    hideSelectedItems={true}
                                    displayExpr="label"
                                    onSelectionChanged={onUserSelectionChanged}
                                />}
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Draggable entiteit
                        </Col>
                        <Col lg="4">
                            {selectFieldsInTable &&
                                <SelectBox
                                    items={selectFieldsInTable}
                                    value={draggableSelectFieldId}
                                    valueExpr="id"
                                    displayExpr="name"
                                    showClearButton={true}
                                    onValueChanged={(e) => {
                                        setDraggableSelectFieldId(e.value);
                                        setDraggableDetailViewId(null);
                                    }}
                                />
                            }
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            Draggable detailview
                        </Col>
                        <Col lg="4">
                            <SelectBox
                                disabled={draggableSelectFieldId == null}
                                items={detailViewsInDraggableTable}
                                value={draggableDetailViewId}
                                valueExpr="id"
                                displayExpr="name"
                                showClearButton={true}
                                onValueChanged={(e) => { setDraggableDetailViewId(e.value) }}
                            />

                        </Col>
                    </Row>
                </Form.Group>

            </ValidationGroup>

        </Popup >
    )
}
export default memo(PopupEditScheduler);

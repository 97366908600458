import React, { memo, useState, useCallback, useEffect } from "react";
import appSettings from "../../appsettings";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import toast from "react-hot-toast";
import saveAs from "file-saver";
import { base64ToBlob } from "base64toblob";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Button from 'devextreme-react/button';
import msalFetch, { acquireTokenRequest } from "../../api/MsalFetch.js";
import { useGraphContext } from "../graph/context";
import { Tooltip } from 'devextreme-react/tooltip';
import moment from "moment";
import FieldLabel from "../detailview/FieldLabel";

const ACTION = ({
    label,
    value,
    action,
    table,
    tableitemId,
    columnId,
    registerLoaded,
    changeInForm,
    forceReload,
    memoField,
}) => {
    const msal = useMsal();
    const { currentUser } = useGraphContext();
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const [isExecuting, setIsExecuting] = useState(false);
    const [hasExecuted, setHasExecuted] = useState(false);
    const link = useHistory();
    const tableId = table.id;
    const {
        id: actionId,
        actionType,
        name,
        refresh,
        oneTime,
    } = action || {};

    const postAction = useCallback(async () => {
        setIsExecuting(true);

        if (!actionId) {
            toast.error("Actie kon niet worden uitgevoerd");
            setIsExecuting(false);
            return;
        }
        if (actionType === "Preview") {
            const toastId = toast.loading(`${label}`);
            const tokenRequest = await acquireTokenRequest();
            const token = tokenRequest.accessToken;
            window.open(
                `${appSettings.api.endpoint}/api/action/${actionId}/${tableId}/${tableitemId}/?orgid=${currentUser?.organisation.id}&access_token=${token}`
            );

            toast.success(`${label}`, { id: toastId });
            setIsExecuting(false);
            return;
        }
        try {
            const toastId = toast.loading(`${label}`);
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/action/${actionId}/${tableId}/${tableitemId}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                }
            );

            const data = await res.json();


            // check if succesfull and deal with action
            if (data.warning) {
                toast(data.result, {
                    style: {
                        border: "1px solid orange",
                        background: "white",
                    },
                });
            } else if (data.success) {
                if (actionType === "Download") {
                    saveAs(base64ToBlob(data.result), data.filename);
                    setIsExecuting(false);
                    toast.success(data.filename + " download beschikbaar", { id: toastId });
                    // toast.success("Item is aangepast", { id: toastId });
                }

                if (actionType !== "Download") {
                    toast.success(`${label}${data.result ? `: ${data.result}` : ''}`, { id: toastId });
                }

                if (refresh) {
                    forceReload();
                }
            } else {
                toast.error(data.result ? data.result : "Er is een fout opgetreden.");
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
        }
        setHasExecuted(true);

        setIsExecuting(false);
    }, [actionId, actionType, refresh, link, tableId, tableitemId]);

    useEffect(() => {
        onInitialized()
    }, [onInitialized]);

    const lastRunPretty = value ? moment(value).format('DD-MM-yyyy H:mm') : "";

    const disabled = !tableitemId || (oneTime && value) || (oneTime && hasExecuted);

    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={label}
                        columnId={columnId}
                        memoField={memoField}

                    />
                </Col>
                <Col md={"8"}>
                    <div className="actionproperty">
                        <Button
                            // className='bloom-action-button'
                            stylingMode='contained'
                            type="default"
                            // width={"50px"}
                            // text={label}
                            hint={changeInForm ? "Graag eerst wijzigingen opslaan" : (value ? `Laatste start: ${lastRunPretty}` : "Nog niet uitgevoerd")}
                            disabled={(disabled || changeInForm || isExecuting)}
                            icon={`fas ${isExecuting ? 'fa-pulse fa-spinner' : (action?.icon ? action.icon : "fa-bolt")}`}
                            onClick={!disabled ? postAction : () => { }}
                        />
                        <p className="lastrun">{lastRunPretty}</p>
                    </div>
                </Col>

            </Row>
        </Form.Group >
    );
};

export default memo(ACTION);

import React, { memo, useState, useEffect, useCallback } from "react";
import appSettings from "../../appsettings";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import msalFetch from "../../api/MsalFetch.js";
import FieldLabel from "../detailview/FieldLabel";
import SelectionList from "../Fields/SelectionList.js";

//@TODO: fix de focusOut nog

const SL = ({
    label,
    mandatory,
    readonly,
    option,
    // registerLoaded,
    onControlUpdate,
    memoField,
    columnId,
    value,
    getValueByColumnId,
    cascadingParentColumnId,
    typeAheadCharacters,
    // detailviewId,
    // controlPropertyId,
    inputTableId,
    inputDisplayColumnId,
    columnHasGroup,
    withMagnifyingGlass = true,
    showGoToDetailview = true,
    inputListId,
    // getColumnByColumnId,
    columnHasImage
}) => {
    const [preferredDetailviewId, setPreferredDetailviewId] = useState(null);
    const [customLabel, setCustomLabel] = useState(option?.infoLabel);
    const cascadingParentItemId = getValueByColumnId(cascadingParentColumnId);


    const onSelectionChanged = useCallback((selection) => {
        const selectedValue = selection.selectedItem?.value || null;
        if (selectedValue === value) {
            return;
        }
        setCustomLabel(selection.selectedItem?.infoLabel);
        //console.log("onSelectionChanged", selection);

        onControlUpdate(columnId, selection.selectedItem?.value ?? null, { cascadingParentIdFromDirectChildSelection: selection?.selectedItem?.cascadingParentValue });
    }, [onControlUpdate, columnId, value]);


    // when value is no longer in options, load directly from table to show as placeholder
    const [deprecatedOption, setDeprecatedOption] = useState(null);
    useEffect(() => {
        const getDeprecatedOption = async () => {
            if (value && !option) {
                // console.log(`Deprecated:  ${inputDisplayColumnId}`, value);
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/table/column/${value}/${inputDisplayColumnId}`);
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(`[SELECT] getDeprecatedOption for value ${value} response was not ok: ${data.message}`);
                }
                setDeprecatedOption(data);
            }
        };
        getDeprecatedOption();
    }, [value, option, inputDisplayColumnId]);

    const fetchOptionDetailview = useCallback(async (itid) => {
        try {
            if (!value) {
                return false;
            }
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/table/${itid}`);
            const data = await response.json();
            if (data?.preferredDetailviewId) {
                setPreferredDetailviewId(data.preferredDetailviewId);
            }
        } catch (error) {
            throw new Error("Data Loading Error");
        }
    }, [value]);

    useEffect(() => {
        fetchOptionDetailview(inputTableId);
    }, [fetchOptionDetailview, inputTableId]);




    return (<Form.Group>
        <Row>
            <Col md="4">
                <FieldLabel
                    value={value}
                    label={label}
                    onControlUpdate={onControlUpdate}
                    memoField={memoField}
                    mandatory={mandatory}
                    columnId={columnId}
                    readonly={readonly}
                    showGoToDetailview={showGoToDetailview}
                    preferredDetailviewId={preferredDetailviewId}
                    inputListId={inputListId}
                    withMagnifyingGlass={withMagnifyingGlass}
                />
            </Col>
            <Col md="8">
                <SelectionList
                    key={cascadingParentItemId} // this is important!
                    // they key makes it so, that when cascadingParentItemId changes,
                    // the key will change and so the SelectionList will be unmounted
                    // and mounted again, to clear it's datasource
                    // -- Laurens Kling, Zarautz, 2024
                    columnId={columnId}
                    defaultValue={option}
                    className={null}
                    typeAheadCharacters={typeAheadCharacters}
                    label={label}
                    initialValue={value}
                    readonly={readonly}
                    cascadingParentItemId={cascadingParentItemId}
                    onSelectionChanged={onSelectionChanged}
                    onValueChanged={null}
                    deprecatedOption={deprecatedOption}
                    columnHasGroup={columnHasGroup}
                    columnHasImage={columnHasImage}
                    setCustomLabel={setCustomLabel}
                />
            </Col>
        </Row>
        {
            customLabel && (
                <Row className="form-row-hidden">
                    <Col md="4">
                    </Col>
                    <Col md="8">
                        <Form.Label>
                            <div className="bloom-select-search-inputlabel" dangerouslySetInnerHTML={{ __html: customLabel }}></div>
                        </Form.Label>
                    </Col>
                </Row>
            )
        }

    </Form.Group >)
};

export default memo(SL);

import Scheduler from "./Scheduler";
import React, { } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useGraphContext } from "../graph/context.js";
import BloomFooter from "../footer/BloomFooter.js";
import { useGlobalContext } from "../../context/context.js";

const SchedulerWrapper = ({
    schedulerId,
    title,
    users,
    dataStore,
    selectedUsers,
    setSelectedUsers,
    filterOnOnlineUser,
    usersOfScheduler,
    preferredDetailViewId,
    cellDuration,
    startDayHour,
    endDayHour,
    tableId,
    draggableSelectFieldId,
    draggableItems,
    draggableSelectFieldAlias,
    getDraggableItems,
    draggableDetailViewId,
    defaultAppointmentLength,
}) => {
    const { currentUser } = useGraphContext();
    const isMobile = useGlobalContext();

    return (users &&
        <>
            <main className={`bl-content bl-card ${isMobile ? "mobile" : ""}`}>
                <Helmet>
                    <title>
                        {`${title}`}
                    </title>
                </Helmet>
                {/* <header className="bloom-list-header">
                    <h3 className="listHeader">{`${title}`}</h3>
                </header> */}
                <section className="splitteritem">
                    <Scheduler
                        title={title}
                        schedulerId={schedulerId}
                        users={users}
                        dataStore={dataStore}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        filterOnOnlineUser={filterOnOnlineUser}
                        usersOfScheduler={usersOfScheduler}
                        preferredDetailViewId={preferredDetailViewId}
                        cellDuration={cellDuration}
                        startDayHour={startDayHour}
                        endDayHour={endDayHour}
                        draggableSelectFieldId={draggableSelectFieldId}
                        draggableItems={draggableItems}
                        draggableSelectFieldAlias={draggableSelectFieldAlias}
                        getDraggableItems={getDraggableItems}
                        draggableDetailViewId={draggableDetailViewId}
                        defaultAppointmentLength={defaultAppointmentLength}
                    />
                </section>

            </main>

            <footer className="bl-footer">


                {currentUser?.admin === 1 && (
                    <Link
                        to={`/configurator/dataset/${tableId}/4/list/${schedulerId}/`}
                    >
                        <div title="Bewerk lijst" className="control-configurator-icon"><i className={"fa-regular fa-list"} /></div>
                    </Link>
                )}
                {currentUser?.admin === 1 && (
                    <Link
                        to={`/configurator/dataset/${tableId}/`}
                    >
                        <div title="Bewerk tabel" className="control-configurator-icon"><i className={"fa-regular fa-table"} /></div>
                    </Link>
                )}
                <BloomFooter />
            </footer>
        </>
    )
};

export default SchedulerWrapper;

import React, { useCallback, useContext } from 'react';
import Button from 'devextreme-react/button';
import { ThemeContext } from '../../../theme/theme';

export const ThemeSwitcher = () => {
    const { switchTheme, theme } = useContext(ThemeContext);

    const onButtonClick = useCallback(() => {
        switchTheme();
    }, [switchTheme]);

    return (
        <div>
            <Button
                className='theme-button'
                stylingMode='text'
                text="Wissel thema"
                hint='Verander kleurmodus'
                icon={theme !== 'dark' ? 'moon' : 'sun'}
                onClick={onButtonClick}
            />
        </div>
    );
};
